.membership_wrapper {
  padding: 90px 90px 28px;
}

.membership-wrapper2 {
  padding: 60px 120px 60px;
}

.membership_detail ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.membership_detail ul li {
  display: flex;
  align-items: center;
  height: 64px;
  border-top: 2px solid #f2f2f2;
}

.membership_detail ul li:last-of-type {
  border-bottom: 2px solid #f2f2f2;
}

.membership_detail ul li p {
  text-align: left;
  width: 100%;
  margin-bottom: 0;
}

.membership_detail ul li p:first-of-type {
  font-weight: bold;
  text-transform: uppercase;
}

.membership_detail ul li p:last-of-type {
  text-align: right;
}

.button.btn-activate {
  padding: 10px 30px;
  width: auto;
  height: auto;
}

.membership-wrapper2 .membership_note {
  padding: 30px;
  border: 2px solid #fe9168;
  border-radius: 4px;
}

.membership-block .membership-select-header {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}

.membership-block .btn-group {
  position: relative;
  width: 320px;
}

.membership-block .btn-group .dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.action-group.membership-action p {
  margin-top: 50px;
  margin-bottom: 0;
}

.action-group.membership-action span {
  font-size: 14px;
  color: #fe9168;
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 1px;
  margin-bottom: 0;
  cursor: pointer;
}

/* Membership Edit */
.membership-edit .form-control {
  height: 50px !important;
}

.membership-edit .form-group label,
.membership-edit p.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 36px;
}

.membership-edit .card-header .title p {
  margin-left: 48px;
}

.membership-edit .card-body {
  padding: 52px 80px;
  min-height: 240px;
  position: relative;
}

.membership-edit .card-body .card-block {
  padding: 32px 0 0 0;
}

.membership-edit .card-choose ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.membership-edit .card-choose ul li p {
  margin-bottom: 0;
}

.membership-edit .card-choose ul li {
  border-top: 2px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.membership-edit .card-choose ul li:last-child {
  padding-top: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.membership-edit .card-choose li label {
  font-weight: bold;
  text-transform: uppercase;
}

.membership-edit .card-detail {
  margin-top: 40px;
}

.membership-edit .card-detail .btn-group .btn {
  height: 50px;
  font-style: italic;
  color: #3d3d3d;
  border-radius: 0;
}

.membership-edit .securityCode {
  line-height: 50px;
  text-decoration: underline;
  letter-spacing: 1px;
  color: #fe9169;
}

/* -- radio Button style */
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #3d3d3d;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #c2c2c2;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fe8462;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 1190px) {
  .membership-wrapper2 {
    padding: 40px 80px 40px;
  }
}

@media screen and (max-width: 860px) {
  .membership-wrapper2 {
    padding: 30px 60px 30px;
  }
}

@media screen and (max-width: 768px) {
  /* MemberShip Edit */
  .membership-edit #first_name {
    margin-bottom: 16px;
  }
  .membership_wrapper {
    padding: 40px 0px 28px;
  }
}

@media screen and (max-width: 700px) {
  .membership-edit .card-body {
    padding: 52px 40px;
  }
}

@media screen and (max-width: 598px) {
  .membership-edit .card-choose ul li:first-child {
    display: block;
    height: auto;
    line-height: 54px;
  }

  .membership-edit .card-choose ul li:first-child p {
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 590px) {
  .membership_detail ul li .currentpm {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 549px) and (min-width: 481px), (max-width: 465px) {
  .membership_detail ul li .subPackage {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 500px) and (min-width: 481px),
  (max-width: 416px) and (min-width: 380px),
  (max-width: 341px) {
  .membership_detail ul li .autoRD {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .membership-edit .card-body {
    padding: 52px 20px;
  }

  .membership-edit .form-group label,
  .membership-edit p.title {
    font-size: 16px;
  }
}

@media screen and (max-width: 380px) {
  .membership_detail ul li p {
    font-size: 14px;
  }
}
