.service-times-wrapper {
  margin: -1.25em;
}

.service-times-wrapper .st-dropdown {
  display: flex;
  align-items: center;
  padding: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.service-times-wrapper .st-dropdown .st-show {
  margin-bottom: 0;
  line-height: 34px;
  margin-right: 10px;
}

.service-times-wrapper .form-control {
  height: 50px;
  text-align: center;
  border-radius: 0;
}

.service-times-wrapper .form-control.error,
.service-times-wrapper .error .form-control,
.service-times-wrapper .time-picker.error {
  border: 1px solid rgba(255, 0, 0, 0.65);
}

.service-times-wrapper .time-picker {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  height: 50px;
}

.service-times-wrapper .time-picker input {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
}

.service-times-wrapper .time-picker > div {
  color: #464a4c !important;
  margin: auto;
}

.service-times-wrapper .time-picker hr {
  display: none;
}

.service-times-wrapper .service-block.card {
  position: relative;
  padding: 0px 32px 32px 32px;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #f2f2f2;
}

.service-times-wrapper .service-block.card > .card-header {
  border: none;
}

.service-times-wrapper .service-block.card > .card-header .title-buttons {
  padding: 0px;
}

.service-times-wrapper .service-block.card > .card-header .title-buttons .button {
  min-width: 140px;
  margin: 4px;
}

.service-times-wrapper .service-block.card > .card-body {
  border: 2px solid #f2f2f2;
  border-radius: 30px;
  box-shadow: none;
  padding: 0px;
}

.service-times-wrapper .service-block .st-name {
  width: 36%;
  max-width: 36%;
  text-align: center;
  padding: 0 20px;
  min-width: 360px;
}

.service-times-wrapper .service-block .st-name .form-control {
  text-align: left;
}

.service-times-wrapper .service-block .st-start-time,
.service-times-wrapper .service-block .st-end-time {
  width: 20%;
  max-width: 20%;
  text-align: center;
  padding: 0 20px;
  min-width: 160px;
}

.service-times-wrapper .service-block .st-action {
  width: 24%;
  max-width: 24%;
  text-align: center;
  min-width: 200px;
}

.service-times-wrapper .service-block .st-action button {
  margin: 4px;
}

.service-times-wrapper .service-block .card-block {
  padding: 20px 0;
}

.service-times-wrapper .service-block .card-block p {
  font-style: italic;
  font-size: 18px;
  color: #adadad;
  padding-left: 46px;
  padding-right: 46px;
}

.service-times-wrapper .service-block .table-header {
  display: flex;
  border-bottom: none;
}

.service-times-wrapper .service-block .table-header .st-name,
.service-times-wrapper .service-block .table-header .st-start-time,
.service-times-wrapper .service-block .table-header .st-end-time,
.service-times-wrapper .service-block .table-header .st-action {
  text-align: center;
  border-left: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  padding: 12px;
}

.service-times-wrapper .service-block .table-header .st-name {
  border-left: none;
}

.service-times-wrapper .service-block .service-item {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.service-times-wrapper .service-block .service-item .helper-text {
  display: block;
}

.service-times-wrapper .service-block .service-item .item-cell,
.service-times-wrapper .service-block .service-item .time-picker input,
.service-times-wrapper .service-block .service-item .input-box input {
  padding: 0px 10px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.service-times-wrapper .service-block .service-item .item-cell {
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
}

.service-times-wrapper .service-block.card input.form-control::placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
}

@media screen and (max-width: 574px) {
  .service-times-wrapper .st-update-btn .st-update {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}
