@import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

.gallery-wrapper {
  padding: 52px 15%;
}

.gallery-wrapper .gallery {
  position: relative;
}

.gallery-wrapper .gallery .gallery-add-btn {
  position: absolute;
  top: auto;
  bottom: 6px;
  left: 0;
  padding: 0;
  transform: translateX(calc(-100% - 10px));
  width: 88px;
  min-width: 88px;
  height: 68px;
  border-radius: 20px;
  font-size: 30px;
}

.gallery-wrapper .gallery .gallery-add-btn img {
  width: 22px;
  vertical-align: inherit;
  margin-right: 10px;
}

.gallery-wrapper .gallery .image-gallery button:focus {
  box-shadow: none;
}

.gallery-wrapper .image-gallery-index {
  top: inherit;
  right: 46%;
  bottom: 20px;
  color: #fe9168;
  background: none;
  font-style: italic;
  font-weight: bold;
}

.gallery-wrapper .image-gallery-left-nav,
.gallery-wrapper .image-gallery-right-nav {
  font-size: 56px;
}

.gallery-wrapper .image-gallery-left-nav::before,
.gallery-wrapper .image-gallery-right-nav::before {
  color: #fe9873;
  text-shadow: none;
}

.gallery-wrapper .image-gallery-left-nav:focus,
.gallery-wrapper .image-gallery-right-nav:focus {
  box-shadow: inherit;
}

.gallery-wrapper .image-gallery-left-nav {
  left: -70px;
}

.gallery-wrapper .image-gallery-right-nav {
  right: -70px;
}

.gallery-wrapper .image-gallery-thumbnails-container {
  text-align: left;
  height: 62px;
}

.gallery-wrapper .image-gallery-thumbnails-container > button {
  width: 68px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.gallery-wrapper .image-gallery-thumbnails-container > button img {
  width: 100%;
  height: 62px;
  object-fit: contain;
}

.gallery-wrapper .image-gallery-thumbnails-container > button > .image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.gallery-wrapper .image-gallery-thumbnails-wrapper {
  width: 100%;
}

.gallery-wrapper .image-gallery-slide-wrapper {
  margin-bottom: 20px;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slides,
.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
  height: 420px;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide > div .image-gallery-image {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.gallery-wrapper .gallery .remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 340px;
  }

  .gallery-wrapper .image-gallery-thumbnail.active {
    border: 4px solid #337ab7;
  }

  .gallery-wrapper .gallery .gallery-add-btn {
    position: relative;
    width: 100%;
    height: 48px;
    transform: none;
    left: auto;
    bottom: auto;
  }
  .gallery-wrapper .gallery .remove-btn {
    right: 40% !important;
  }
}

@media screen and (max-width: 634px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 320px;
  }

  .gallery-wrapper .image-gallery-right-nav {
    right: -52px;
  }

  .gallery-wrapper .image-gallery-left-nav {
    left: -52px;
  }

  .gallery-wrapper .gdeletebtn {
    padding-top: 5px;
    font-size: 16px;
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 540px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 260px;
  }
}

@media screen and (max-width: 354px) {
  .gallery-wrapper .image-gallery-left-nav,
  .gallery-wrapper .image-gallery-right-nav {
    font-size: 36px;
  }

  .gallery-wrapper .image-gallery-right-nav {
    right: -46px;
  }

  .gallery-wrapper .image-gallery-left-nav {
    left: -46px;
  }
}
