.group .group-section {
  margin: 32px 0px;
}

.group .group-detail,
.group .group-edit,
.group .group-create {
  position: relative;
  padding: 24px 36px;
}

.group .group-detail .group-status {
  position: absolute;
  right: 0;
  top: 0;
}

.group .group-detail .group-status svg {
  font-size: 36px;
  height: 0.875em;
  width: 0.875em;
  box-sizing: content-box;
  margin: 4px;
}

.group .group-detail .members-content,
.group .group-detail .notifications-content,
.group .group-detail .events-content {
  margin: 24px;
}

.group .group-detail .group-section .group-details-title {
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
}

.group .group-detail .group-section .collapsible-paragraph-container {
  margin-bottom: 24px;
}

.group .group-detail .group-section .group-details-created {
  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #8b8b8b;
}

.group .group-create .react-autosuggest__suggestions-container--open {
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 5px 0px 15px 0px;
  border: 2px solid #fe9168;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.group .group-create .react-autosuggest__suggestions-container--open ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.group .group-create .react-autosuggest__suggestions-container--open ul li {
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
}

.group .group-create .react-autosuggest__suggestions-container--open ul li:hover,
.group .group-create .react-autosuggest__suggestion--highlighted {
  color: white;
  background-color: #fe9168;
}

@media screen and (max-width: 767px) {
  .group .group-detail,
  .group .group-edit,
  .group .group-create {
    padding: 12px 18px;
  }
}

@media screen and (max-width: 434px) {
  .group .group-detail,
  .group .group-edit,
  .group .group-create {
    padding: 0px;
  }
}
