.notification-list.card {
  max-width: 420px;
  max-height: calc(100% - 2rem);
  margin: 1rem;
  margin-left: auto;
  overflow: hidden;
  border-radius: 30px;
}

.notification-list.card > .card-header {
  position: relative;
  background-color: #46c9d4;
}

.notification-list.card > .card-header > .title {
  min-width: unset;
  padding-left: 2rem;
  padding-right: 2rem;
}

.notification-list.card > .card-header .back {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 0.5rem;
  bottom: auto;
}

.notification-list.card > .card-header .back button {
  border-bottom: none;
}

.notification-list.card > .card-header p {
  text-transform: capitalize;
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.notification-list.card > .card-block {
  padding: 0;
  min-height: 80px;
}

.notification-list.card > .card-footer {
  position: relative;
  padding: 0;
}

.notification-list.card .noti-count {
  display: inline-block;
  margin: 0.5em;
  border-radius: 4em;
  text-align: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
}
