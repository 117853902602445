@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400i);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
div.avatar-modal > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.avatar-modal > div > div {
  overflow: auto !important;
}

div.user-avatar {
  position: relative;
}

div.user-avatar .avatar-hover {
  display: none;
  cursor: pointer;
}

div.user-avatar:hover .avatar-hover {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #24242486;
}

div.user-avatar .avatar-hover > .hover-icon {
  display: none;
}

div.user-avatar:hover .avatar-hover > .hover-icon {
  display: block;
  width: 30%;
  height: 30%;
  max-width: 40px;
  max-height: 40px;
}

div.user-avatar:hover .avatar-hover > span {
  color: white;
  text-transform: uppercase;
}

.avatar-badge {
  position: absolute;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.avatar-badge.photo-badge {
  font-size: 48px;
  padding: 4px;
  background: #00bcd4;
  border-radius: 50px;
  border: 3px solid white;
  color: white;
}

.avatar-badge.star-badge {
  font-size: 24px;
  color: #f7ac02;
  right: -2px;
  bottom: -2px;
}


@media screen and (max-width: 425px) {
 #action-button-container{
   flex-direction: column;
   grid-gap: .4rem;
   gap: .4rem;
 }
}
/* PrayerModal */
.prayer-modal,
.prayer-detail-top {
  display: flex;
}

.prayer-modal {
  padding: 40px 30px 20px;
  position: relative;
}

.prayer-modal .id-text {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  color: #fe9b6e;
}

.prayer-img {
  position: relative;
  height: -webkit-max-content;
  height: max-content;
}

.prayer-detail {
  padding-left: 24px;
  width: 100%;
}

.prayer-detail-top .info {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
}

.prayer-detail-top .info .name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.prayer-detail-top .info .date {
  font-size: 13px;
}

.prayer-detail-top .contact {
  margin-left: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: right;
}

.prayer-detail-top .contact span {
  display: inline-block;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}

.prayer-detail-top .contact span svg {
  margin-right: 6px;
  color: #fe9b6e;
}

.prayer-detail-bottom {
  display: block;
  margin-top: 10px;
}

.prayer-detail-bottom .text {
  width: 70%;
}

.prayer-detail-bottom .text p {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #a4a4a4;
}

.prayer-detail-bottom .learn-button {
  text-align: right;
}

.prayer-detail-bottom .learn-button .button.neutral {
  padding: 10px 20px;
  font-size: 13px;
}

.position-content {
  display: flex;
  padding: 38px 40px 40px;
  position: relative;
}

.position-content .back-prayer {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 36px;
  left: 30px;
  cursor: pointer;
}

.position-content .back-prayer img {
  width: 34px;
  height: 100%;
}

.position-content .shield-icon {
  padding-left: 90px;
}

.position-content .shield-icon img {
  padding-top: 4px;
}

.position-content .info {
  padding-left: 20px;
}

.position-content .info h5 {
  font-size: 15px;
  font-weight: bold;
}

.position-content .info p {
  font-size: 14px;
  margin-bottom: 0;
  color: #a4a4a4;
}

@media screen and (max-width: 817px) {
  .prayer-detail-top .contact span:first-child {
    padding-left: 0;
  }

  .position-content .shield-icon {
    padding-left: 50px;
  }
}

@media screen and (max-width: 712px) {
  .prayer-detail-bottom .text,
  .prayer-detail-bottom .learn-button {
    width: auto;
  }

  .prayer-detail-bottom .learn-button {
    margin-top: 16px;
  }
}

@media screen and (max-width: 596px) {
  .prayer-modal,
  .position-content,
  .prayer-detail-top {
    display: block;
  }

  .prayer-detail {
    padding-left: 0;
    text-align: center;
  }

  .prayer-img {
    text-align: center;
    margin-bottom: 16px;
  }

  .prayer-img span {
    position: relative;
  }

  .prayer-detail-top .contact {
    text-align: center;
  }

  .prayer-detail-bottom .learn-button {
    text-align: center;
  }

  .position-content .back-prayer {
    top: 32px;
    left: 32px;
  }

  .position-content .shield-icon {
    text-align: center;
    padding-left: 0px;
    margin-bottom: 16px;
  }

  .position-content .info {
    padding-left: 0;
  }
}

@media screen and (max-width: 493px) {
  .prayer-detail-top .contact span {
    display: block;
    padding-left: 0%;
    margin-bottom: 10px;
  }

  .prayer-detail-top .contact {
    margin-top: 10px;
  }
}

/* End PrayerModal */

.loading {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.loading svg {
  width: inherit;
  height: inherit;
}

[class*='Form__formContainer'] {
  position: relative;
  background-color: white;
  padding: 35px 40px;
  border-radius: 30px;
  border: 0px;
  box-shadow: 1px 1px 2px 0px lightgrey;
  margin: 30px auto 0px;
  width: 780px;
  min-width: 320px;
}

[class*='Toast__toast_'] {
  width: 780px;
  min-width: 320px;
  margin: 4px auto;
  padding: 10px;
  background-color: steelblue;
  border-radius: 8px;
  border: 0px;
  box-shadow: 1px 1px 2px 0px lightgrey;
  position: relative;
}

[class*='Toast__toast_'] [class*='Toast__toastClose'] {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-image: url(/static/media/close-x.edeef416.png);
  background-size: contain;
}

[class*='Form__formContainer'] .close-button-container {
  position: absolute;
  display: block;
  right: 40px;
  top: 40px;
}

[class*='Form__formContainer'] .close-button-container > .close-button {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] {
  width: 340px;
  margin: auto;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Anchor__a'] {
  cursor: pointer;
  color: lightsalmon;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Anchor__a']:hover {
  color: salmon;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionBody'] {
  position: relative;
  min-height: 80px;
}

[class*='Form__formContainer']
  > [class*='Form__formSection']
  [class*='Section__sectionBody']
  [class*='Form__formField'] {
  margin-bottom: 20px;
}

[class*='Form__formContainer']
  > [class*='Form__formSection']
  [class*='Section__sectionBody']
  [class*='Form__formField']
  [class*='Input__input'] {
  width: 100%;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionHeader'] {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionHeader'] > img {
  width: 38px;
  height: auto;
}

[class*='Form__formContainer']
  > [class*='Form__formSection']
  [class*='Section__sectionHeader']
  > [class*='Section__sectionHeaderContent'] {
  text-transform: uppercase;
  color: salmon;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionFooter_'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

[class*='Form__formContainer'] > [class*='Form__formSection'] [class*='Section__sectionFooter_'] button {
  background: lightsalmon;
  border: none;
  height: 56px;
  width: 200px;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 4em;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

[class*='Form__formContainer']
  > [class*='Form__formSection']
  [class*='Section__sectionFooter_']
  [class*='Section__sectionFooterPrimaryContent'] {
  margin-top: 20px;
}

[class*='Form__formContainer']
  > [class*='Form__formSection']
  [class*='Section__sectionFooter_']
  [class*='Section__sectionFooterSecondaryContent'] {
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  [class*='Form__formContainer'],
  [class*='Toast__toast_'] {
    width: 514px;
  }
  [class*='Form__formContainer'] .close-button-container {
    right: 20px;
    top: 20px;
  }
}

@media screen and (max-width: 532px) {
  [class*='Form__formContainer'],
  [class*='Toast__toast_'] {
    width: 320px;
  }

  [class*='Form__formContainer'] > [class*='Form__formSection'] {
    width: 180px;
  }
}

.app-subheader > .row {
    height: 100%;
}

.app-header,
.app-subheader {
    padding: 0 50px;
}

.app-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background: #212121;
    height: 60px;
}

.app-subheader {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: auto;
    background: #ffae8d;
    height: 52px;
}

.step-header {
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
}


.alert-banner-container {
    position: absolute;
    top: 112px;
    left: 0;
    right: 0;
    background: #f80505e8;
    font-size: 14px;
    padding: 4px 12px;
    text-align: center;
}

.alert-banner-container .link {
    color: #fff !important;
    text-decoration: none;
    background-color: transparent;
}

.d-info {
    color: #fff;
    font-size: 14px;
    letter-spacing: 1.4px;
    margin-top: 20px;
    overflow: visible;
}

.d-info > p {
    overflow: visible;
    white-space: nowrap;
}

.d-logo {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.d-usermenu {
    margin-top: 9px;
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.name--user {
    display: inline-block;
    color: #fff;
    vertical-align: top;
    font-weight: bold;
    margin: 0px 0px 0px 8px;
    letter-spacing: 1.6px;
    cursor: pointer;
    white-space: break-spaces;
    word-break: break-word;
    text-align: center;
}

.name--user .arrow {
    font-size: 26px;
    color: #46c9d4;
}

.d-sub-left {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.d-sub-left .wrapper {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.name--church {
    color: #fff;
    font-size: 17px;
    letter-spacing: 2.5px;
    vertical-align: middle;
    margin: 3px 3px 0;
}

.notification {
    position: relative;
}

.notification .noti-count-icon-text {
    position: absolute;
    right: 1px;
    top: 5px;
    border-radius: 4em;
    text-align: center;
    background-color: #46c9d4;
    color: #fff;
    font-size: 11px;
    line-height: 13px;
    width: 13px;
    height: 13px;
}

.d-sub-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.d-sub-right .sub-menu {
    letter-spacing: 1.6px;
    color: #fff;
    font-size: 18px;
    display: inline-block;
}

.d-sub-right .sub-menu svg {
    font-size: 27px;
}

.profile-modal,
.menu-modal {
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
}

.profile-modal .user--name {
    margin-top: 82px;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 0;
}

.profile-modal .button {
    width: 186px;
    height: 58px;
    margin: 12px;
}

.menu-modal {
    width: 455px;
}

.menu-modal ul {
    padding: 0;
    margin-bottom: 0;
}

.menu-modal li {
    list-style: none;
    padding: 22px 32px;
    font-size: 28px;
    border-bottom: 1px solid #d8d8d8;
    text-align: left;
    cursor: pointer;
}

.menu-modal li > a {
    width: 100%;
    display: flex;
}

.menu-modal li .subp-img {
    width: 40px;
}

.menu-modal li span {
    margin-left: 18px;
    color: black;
    width: 100%;
}

@media screen and (max-width: 776px) {
    .app-header,
    .app-subheader {
        padding: 0 20px;
    }

    .d-info {
        display: none;
    }

    .d-logo {
        text-align: left;
    }

    .rbc-container .rbc-calendar .toolbar-container .rbc-back-next {
        text-align: left !important;
        font-size: 18px !important;
    }

    .rbc-container .rbc-calendar .toolbar-container .rbc-month-label {
        margin: 0 !important;
    }

    .event-page {
        -webkit-transform: scale(.7);
                transform: scale(.7);
    }
}

@media screen and (max-width: 493px) {
    .menu-modal,
    .profile-modal {
        width: 353px;
    }

    .menu-modal li a {
        font-size: 26px;
    }

    .back {
        top: 14px !important;
    }
}

@media screen and (max-width: 436px) {
    .name--user span {
        display: none;
    }

    .d-sub-left .name--church {
        font-size: 14px;
        line-height: 26px;
    }
}

@media screen and (max-width: 370px) {
    .d-sub-left .name--church {
        letter-spacing: 0;
    }

    .menu-modal,
    .profile-modal {
        width: 100%;
    }

    .menu-modal li a {
        font-size: 21px;
        line-height: 42px;
    }
}

.user-avatar-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
}

.user-avatar-container > div + span {
  padding-left: 22px;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}

.collapsible-paragraph-container {
  position: relative;
  margin: 0px;
  width: 100%;
}

.collapsible-paragraph-container .collapsible-paragraph {
  line-height: 20px;
  letter-spacing: 0px;
  position: relative;
  margin: 0px;
  width: 100%;
}

.collapsible-paragraph-container .expand-handler {
  -webkit-user-select: none;
          user-select: none;
  color: #fe9168;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;
}

.noti-item .noti-item-text {
  border-radius: 16px;
  border: 0;
  overflow: hidden;
  background-color: #46c9d4;
  margin: 0;
}

.noti-item .noti-item-text.background-info {
  background-color: #46c9d4;
  border: 1px solid #46c9d4;
}

.noti-item .noti-item-text.background-warning {
  background-color: #ff9800;
  border: 1px solid #ff9800;
}

.noti-item .noti-item-text.background-danger {
  background-color: #f44336;
  border: 1px solid #f44336;
}

.noti-item .noti-item-text.background-success {
  background-color: #4caf50;
  border: 1px solid #4caf50;
}

.noti-item .noti-item-text .noti-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0.5em;
}

.noti-item .noti-item-text .noti-header .noti-type {
  font-size: 16px;
  font-weight: bold;
  flex: 1 1;
}

.noti-item .noti-item-text .noti-header .noti-new-badge {
  background-color: #ff642c;
  border-radius: 10px;
  font-size: 12px;
  padding: 0.2em 0.4em;
  color: white;
}

.noti-item .noti-item-text .noti-header .noti-close {
  visibility: hidden;
  position: absolute;
  right: 4px;
  background: lightgray;
  font-size: 14px;
}

.noti-item:hover .noti-item-text .noti-header .noti-new-badge {
  visibility: hidden;
}

.noti-item:hover .noti-item-text .noti-header .noti-close {
  visibility: visible;
}

.noti-item .noti-item-text .noti-body {
  position: relative;
  padding: 0.5em 1em;
  background-color: white;
  min-height: 60px;
  font-size: 14px;
}

.noti-item .noti-item-text .noti-body .noti-subheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em 0;
}

.noti-item .noti-item-text .noti-body .noti-title {
  font-size: 14px;
  font-weight: bold;
  flex: 1 1;
}

.noti-item .noti-item-text .noti-body .noti-date {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  font-size: 11px;
  font-weight: normal;
}

.notification-list.card {
  max-width: 420px;
  max-height: calc(100% - 2rem);
  margin: 1rem;
  margin-left: auto;
  overflow: hidden;
  border-radius: 30px;
}

.notification-list.card > .card-header {
  position: relative;
  background-color: #46c9d4;
}

.notification-list.card > .card-header > .title {
  min-width: unset;
  padding-left: 2rem;
  padding-right: 2rem;
}

.notification-list.card > .card-header .back {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: auto;
  right: 0.5rem;
  bottom: auto;
}

.notification-list.card > .card-header .back button {
  border-bottom: none;
}

.notification-list.card > .card-header p {
  text-transform: capitalize;
  font-size: 20px;
  flex: 1 1;
  text-align: center;
}

.notification-list.card > .card-block {
  padding: 0;
  min-height: 80px;
}

.notification-list.card > .card-footer {
  position: relative;
  padding: 0;
}

.notification-list.card .noti-count {
  display: inline-block;
  margin: 0.5em;
  border-radius: 4em;
  text-align: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
}

.place-autocomplete-dropdown-container {
  position: absolute;
  background: white;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.place-autocomplete-dropdown-container > div.suggestion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 4px 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: rgba(0, 0, 0, 0.02);
}

.place-autocomplete-dropdown-container > div.suggestion-item:last-child {
  border-bottom: none;
}

.place-autocomplete-dropdown-container > div.suggestion-item.active {
  background-color: rgba(0, 0, 0, 0.15);
}

.pagination .page-link {
  background-color: transparent;
  padding: 0 8px;
  border: none;
  line-height: 40px;
  color: #ff9871;
  font-size: 18px;
  border-radius: 30px !important;
}

.pagination .page-link:focus,
.pagination .page-link:hover {
  color: #000 !important;
  font-weight: bold;
}

.pagination .page-link img {
  width: 12px;
}

.pagination .page-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-link.active {
  border: 2px solid #e2e2e2;
}

.table-count {
  font-weight: bold;
  padding: 0.5em 1em;
  display: block;
  margin: 0 0 -2.3em;
}

.table-count + div.table.card {
  margin-top: 34px;
}

div.table.card .table-row > .table-cell > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  word-wrap: anywhere;
  padding: 2px;
}

div.table.card .table-row > .table-cell > div.align-left {
  justify-content: flex-start;
  margin-left: 12px !important;
}

div.table.card .table-header.table-row > .table-cell {
  text-transform: uppercase;
  font-weight: bold;
}

.table-pagination {
  position: relative;
}

.table-pagination .table-show {
  position: absolute;
  top: 6px;
  right: 8px;
  font-size: 18px;
  color: #ff9871;
  display: flex;
}

.table-pagination .table-show {
  line-height: 24px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.table-pagination .table-show span {
  letter-spacing: 1px;
  font-weight: bold;
  padding-right: 6px;
}

.table-pagination .table-show ul {
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.table-pagination .table-show ul li.page-item {
  list-style-type: none;
  margin-left: 6px;
  margin-right: 6px;
  width: 28px;
  height: 28px;
}

.table-pagination .table-show ul li.page-item:focus,
.table-pagination .table-show ul li.page-item:hover {
  color: #000;
  cursor: pointer;
}

.table-pagination .table-show ul li.page-item.active {
  border: 1px solid #ff9871;
  border-radius: 9999px;
}

@media screen and (max-width: 1000px) {
  .table-pagination .table-show {
    position: inherit;
    justify-content: center;
  }
}

.prayer-table {
  margin: 30px;
}

.prayer-table .date-sort {
  position: relative;
  line-height: 44px;
  text-align: center;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.prayer-table .title {
  text-align: left;
  font-size: 1.02rem;
  font-weight: bold;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  margin-left: 12px !important;
}

.prayer-table .title .remove-icon {
  float: right;
  color: #fe9168;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  line-height: 24px;
  margin: 4px;
  padding: 6px 12px;
  margin-left: auto;
}

.btn-group {
  width: 100%;
}

.btn-group .btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}

.btn-group .btn-secondary:not(:disabled):not(.disabled):hover,
.btn-group .btn-secondary:not(:disabled):not(.disabled):active,
.btn-group .btn-secondary:not(:disabled):not(.disabled):focus,
.btn-group.show .btn-secondary:not(:disabled):not(.disabled):active,
.btn-group.show .btn-secondary:not(:disabled):not(.disabled):focus,
.btn-group.show > .btn-secondary.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #80bdff;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.btn-group .dropdown-toggle {
  width: 100%;
  text-align: left;
}

.btn-group .dropdown-toggle.disabled {
  border: none;
  color: #464a4c;
  background: transparent;
  cursor: default;
}

.btn-group .dropdown-toggle.disabled:focus {
  box-shadow: none;
}

.btn-group .dropdown-toggle::after {
  display: none;
  text-align: right;
}

.btn-group .dropdown-menu {
  width: 100%;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;
}

.btn-group .dropdown-menu .dropdown-item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
  color: #000;
  position: relative;
}

.btn-group .dropdown-menu .dropdown-item::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.btn-group .dropdown-menu .dropdown-item:hover::before {
  background-color: rgba(0, 0, 0, 0.04);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}

.btn-group .dropdown-menu .dropdown-item.active,
.btn-group .dropdown-menu .dropdown-item:active {
  color: #000;
}

.btn-group .dropdown-menu .dropdown-item.active::before,
.btn-group .dropdown-menu .dropdown-item:active::before {
  background-color: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}

.chip-wrapper {
  position: relative;
  text-transform: capitalize;
}

.chip-wrapper .chip {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
}

.MuiIconButton-root.chip-btn {
  width: 30px;
  height: 30px;
  background-color: #ffa37c;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #fff;
  text-align: center;
  padding: 0px;
  z-index: 1;
}

.MuiIconButton-root.chip-btn:hover {
  background-color: #fab191;
}

.MuiPopover-paper.rest-chip-container {
  background: #ffa37c;
  padding: 10px;
  margin-bottom: 0;
  border-radius: 20px;
  font-size: 14px;
  text-align: left;
  line-height: 26px;
  color: #fff;
}

.MuiPopover-paper.rest-chip-container p {
  margin: 0px;
  padding: 0px;
}

.tab-button-group {
  display: block;
  position: relative;
}

.tab-button-group .button:not(:first-child):not(:last-child) {
  border-radius: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.tab-button-group .button:first-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 0px !important;
}

.tab-button-group .button:last-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: 0px !important;
}

/* Search Box */
/* .search-box {
  margin-right: auto;
  width: max-content;
} */

.search-box .input-group {
  height: 36px;
}

.search-box > p {
  font-style: italic;
  color: #a9a9a9;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.search-box .input-group .form-control {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #dedede;
  border: none;
}

.search-box .input-group .form-control::-webkit-input-placeholder {
  font-size: 14px;
  padding-left: 12px;
  font-weight: bold;
  color: #fff;
}

.search-box .input-group .form-control::placeholder {
  font-size: 14px;
  padding-left: 12px;
  font-weight: bold;
  color: #fff;
}

.search-box .input-group .input-group-btn .button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  height: 100%;
  min-height: 100%;
  width: 90px;
  z-index: 4;
  font-size: 14px;
  position: relative;
}

.search-box .react-autosuggest__container {
  height: 100%;
  width: calc(100% - 90px);
}

.search-box .react-autosuggest__input {
  height: 100%;
  width: 100%;
  background: #a7a7a7;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 28px;
  font-size: 16px;
  color: #292929;
  z-index: 4;
  position: relative;
}

.search-box .react-autosuggest__input::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
}

.search-box .react-autosuggest__input::placeholder {
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
}

.search-box .react-autosuggest__suggestions-container--open {
  width: calc(100% + 90px - 6px);
  background: #8a8a8a;
  padding: 20px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: -12px;
  margin-left: 3px;
  z-index: 3;
  position: relative;
}

.search-box .react-autosuggest__suggestions-container--open ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 120px;
  overflow: auto;
}

.search-box .react-autosuggest__suggestions-container--open ul li {
  padding: 5px 20px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.search-box .react-autosuggest__suggestions-container--open ul li:hover {
  color: #8a8a8a;
  background-color: rgb(253, 249, 249);
}

.search-box .react-autosuggest__input--focused {
  outline: 0;
}

.search-box .react-autosuggest__suggestion--highlighted {
  background-color: rgb(253, 249, 249);
}

.react-date-picker {
  min-width: 180px;
  height: 36px;
  color: white;
  position: relative;
}

.react-date-picker .react-date-picker__wrapper {
  width: 100%;
  height: 100%;
  transition: border-radius 138ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 138ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #777777;
  border-radius: 18px;
  border: none;
  box-sizing: content-box;
}

.react-date-picker.react-date-picker--open .react-date-picker__wrapper {
  border-bottom: 2px solid white;
  border-radius: 18px 18px 0px 0px;
}

.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input {
  color: #fff;
  padding-left: 9px;
  padding-right: 9px;
  box-shadow: none;
}

.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input:invalid {
  background-color: transparent;
}

.react-date-picker .react-date-picker__inputGroup .react-date-picker__inputGroup__input__year {
  flex: 1 1;
}

.react-date-picker .react-date-picker__calendar-button {
  width: 42px;
  background: #00000033;
  color: #fff;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.react-date-picker.react-date-picker--open .react-date-picker__calendar-button {
  border-bottom-right-radius: 0px;
}

.react-date-picker .react-date-picker__calendar-button > svg {
  margin: 0 auto;
}

.react-date-picker .react-date-picker__calendar {
  z-index: 99;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  width: 100%;
  min-width: 280px;
}

.react-date-picker .react-date-picker__calendar .react-calendar {
  border: none;
  width: 100%;
}

.react-date-picker .react-date-picker__calendar .react-calendar__month-view__weekdays {
  color: #888888;
}

svg.progress-ring circle {
  transition: stroke-dashoffset 0.35s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

svg.progress-ring {
  color: #ff9f78;
  width: auto;
  height: auto;
}

.price {
  font-size: 55px;
  font-weight: lighter;
  margin-top: 0;
  margin-bottom: 0;
}

.DayPicker {
  min-height: 342px;
}

.DayPicker-NavButton--prev:focus,
.DayPicker-NavButton--next:focus,
.DayPicker-wrapper:focus {
  outline: 0;
}

.DayPicker-Day {
  width: 42px;
  height: 42px;
  padding: 2px;
  font-size: 16px;
  background-clip: content-box;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #fe9168 !important;
}

.DayPicker-Weekday {
  color: #fe9168 !important;
  font-weight: bold;
}

.DayPicker-Day--today {
  /* color: #31c5c3; */
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.d-main .card-header img {
  vertical-align: bottom;
}

.d-main .card-header .title > button.button.neutral.border--red.img-btn {
  margin-left: 24px;
  width: 32px;
  height: 32px;
  padding: 4px 5px 4px 5px;
  border-width: 2px;
}

.d-main .card-header .title > button.button.neutral.border--red.img-btn > img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.d-main {
  max-width: 1400px;
  padding: 0px 24px;
  margin: 34px auto;
}

.d-main-top {
  margin-bottom: 60px;
}

.d-main-top .left {
  padding-right: 0;
}

.d-main-top .left .total-gift {
  margin-bottom: 20px;
}

.d-main-top .left .total-gift .card-block {
  padding: 24px;
}

.d-main-top .left .total-gift .card-block .wrap--info {
  display: flex;
  float: left;
  position: relative;
  min-width: 60px;
  min-height: 60px;
}

.d-main-top .left .total-gift .card-block .wrap--info p {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  font-size: 18px;
  margin-top: 25px;
  margin-left: 30px;
}

.d-main-top .left .total-gift .card-block .wrap--info .price {
  font-size: 55px;
  font-weight: lighter;
  margin-top: 0;
  margin-bottom: 0;
}

.d-main-top .left .total-gift .card-block .wrap--button {
  float: right;
  margin-top: 10px;
  margin-right: 8px;
}

.d-main-top .left .prayer-requ .card-header .title {
  padding: 0.82rem 1.25rem;
}

.d-main-top .prayer-requ .swiper-container {
  min-height: 143px;
}

.d-main-top .prayer-requ .swiper-container .swiper-slide .row {
  width: 86%;
  margin: 0 auto;
}

.d-main-top .prayer-requ .swiper-container .swiper-slide .slide-userImg {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  margin: 10px auto;
}

.d-main-top .prayer-requ .swiper-container .swiper-pagination {
  bottom: 10px;
}

.d-main-top .prayer-requ .content--slide {
  display: flex;
  cursor: pointer;
}

.d-main-top .prayer-requ .content--slide:nth-child(2n) {
  padding-left: 50px;
}

.d-main-top .prayer-requ .content--slide .wrap--user-info {
  padding-left: 16px;
  width: inherit;
}

.d-main-top .prayer-requ .content--slide .wrap--user--top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-main-top .prayer-requ .content--slide .wrap--user--top h6 {
  margin-bottom: 0;
  font-weight: bold;
}

.d-main-top .prayer-requ .content--slide .wrap--user--top .date {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
}

.d-main-top .prayer-requ .content--slide .wrap--user-info .text {
  color: #a4a4a4;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  -webkit-font-smoothing: subpixel-antialiased;
}

.d-main-top .right {
  padding-left: 20px;
}

.d-main-top .right .card.subscribers {
  height: 100%;
}

.d-main-top .right .card.subscribers .card-header {
  padding: 1.094rem 1.25rem;
  background: #212121;
  cursor: pointer;
  color: #fff;
}

.d-main-top .right .card.subscribers .card-block {
  height: 100%;
}

.d-main-top .right .wrap--users {
  width: calc(100% - 60px);
  margin: 0px auto 30px auto;
  height: calc(100% - 30px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.d-main-top .right .wrap--users .wrap--user-2 {
  display: flex;
  justify-content: space-around;
  flex: 0 1;
  align-items: flex-start;
}

.d-main-top .right .wrap--users .wrap--user {
  text-align: center;
  word-break: break-all;
  vertical-align: top;
  cursor: pointer;
}

.d-main-top .right .wrap--users .wrap--user .user--img {
  margin: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.d-main-top .right .wrap--users .wrap--user .user--img.view-more {
  border: 3px solid #fe9168;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  color: #fe9168;
  font-weight: bold;
  padding-top: 10px;
}

.d-main-top .right .wrap--users .wrap--user h6 {
  font-weight: bold;
}

.d-main-bottom {
  margin-bottom: 82px;
}

.d-main-bottom .card .card-header {
  background: #212121;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.d-main-bottom .card .card-header .title {
  color: #fff;
  padding: 1.207rem 1.25rem;
}

.d-main-bottom .card .card-block {
  padding: 50px 120px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-main-bottom .card .card-block .event-calendar {
  min-width: 322px;
  min-height: 342px;
  width: 322px;
}

.d-main-bottom .card .card-block .event-list-container {
  width: calc(100% - 322px);
  max-width: 720px;
}

.d-main-bottom .card .card-block .event-list-container .swiper-container {
  min-height: 300px;
}

.d-main-bottom .icon--plus {
  width: 17px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.d-main-bottom .wrap--event {
  margin: 0 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.d-main-bottom .wrap--event .content--left {
  position: relative;
}

.d-main-bottom .wrap--event .e-title {
  position: relative;
  color: #000;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.d-main-bottom .wrap--event .content--left > a {
  height: 100%;
  display: block;
}

.d-main-bottom .wrap--event .event-item-hover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  width: auto;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.d-main-bottom .wrap--event .event-item-hover > img {
  max-width: 240px;
  max-height: 320px;
  object-fit: contain;
  width: auto;
  height: auto;
  margin: auto;
}

.d-main-bottom .wrap--event .event-item-hover:hover {
  opacity: 0.8;
}

.d-main-bottom .wrap--event .content--right {
  padding: 0 18px;
  position: relative;
  max-width: 360px;
}

.d-main-bottom .wrap--event .content--right .e-datetime {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.d-main-bottom .wrap--event .content--right .date,
.d-main-bottom .wrap--event .content--right .time {
  font-size: 14px;
  font-weight: bold;
  color: #6d6d6d;
  margin-right: 24px;
  margin-bottom: 18px;
}

.d-main-bottom .wrap--event .content--right .par {
  color: #a4a4a4;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 17px;
  line-height: 18px;
}

.d-main-bottom .wrap--event .content--right .button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.d-main-bottom .wrap--event .content--right .button.calendar {
  width: 120px;
  height: 44px;
  padding: 0;
  margin: 4px 2px;
}

.d-footer {
  background: rgb(33, 33, 33);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.d-footer .d-logo {
  margin: 0 16px;
  padding: 8px 0;
}

/* Responsive */
@media screen and (max-width: 1336px) {
  .d-main-top .prayer-requ .content--slide .wrap--user-info .text {
    font-size: 14px;
  }
  .d-main-bottom .card .card-block {
    padding: 50px 80px 10px;
  }
}

@media screen and (max-width: 1164px) {
  .d-main-top .left,
  .d-main-top .right {
    max-width: 100%;
    flex: none;
  }

  .d-main-top .left {
    margin-bottom: 20px;
    padding-right: 15px;
  }

  .d-main-top .prayer-requ .content--slide .wrap--user-info .text {
    font-size: 14px;
  }

  .d-main-bottom .wrap--event {
    display: block;
  }

  .d-main-bottom .wrap--event .event-item-hover > img {
    display: none;
  }
}

@media screen and (max-width: 912px) {
  .d-main-top .prayer-requ .content--slide {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }

  .d-main-top .prayer-requ .content--slide .wrap--user-info .text {
    font-size: 14px;
  }

  .d-main-top .prayer-requ .content--slide:nth-child(2n) {
    padding-left: 0px;
  }

  .d-main-top .prayer-requ .swiper-container .swiper-slide .row {
    padding-left: 10px;
  }

  .d-main-bottom .card .card-block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .d-main-bottom .card .card-block .event-list-container {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .d-main-top .left .total-gift .card-block .wrap--button {
    margin-top: 14px;
  }
}

@media screen and (max-width: 764px) {
  .d-main {
    padding: 0 8px;
  }
}

@media screen and (max-width: 650px) {
  .d-main-top .left .total-gift .card-block .wrap--info {
    text-align: center;
    display: block;
    float: inherit;
  }

  .d-main-top .left .total-gift .card-block .wrap--info .price {
    margin-left: 0;
  }

  .d-main-top .left .total-gift .card-block .wrap--button {
    text-align: center;
    float: inherit;
    margin-right: 0;
  }

  .d-main-top .prayer-requ .swiper-container .swiper-slide .row {
    width: 78%;
  }

  .d-main-bottom .card .card-block {
    padding: 20px 0px 10px;
  }
}

@media screen and (max-width: 460px) {
  .d-main .card-header {
    height: auto;
  }

  .d-main .card-header .su-ques {
    position: relative !important;
    width: 90% !important;
    margin: 0 5% !important;
    display: block !important;
    margin-bottom: 8px !important;
  }

  .d-main-top .prayer-requ .swiper-container .swiper-slide .row {
    width: 76%;
  }

  .d-main-top .prayer-requ .content--slide .wrap--user-info .text {
    font-size: 14px;
  }
}

@media screen and (max-width: 436px) {
  .d-main-top .prayer-requ .content--slide {
    display: block;
  }

  .d-main-top .prayer-requ .swiper-container .swiper-slide .row .slide-userImg {
    text-align: center;
    margin-bottom: 20px;
  }

  .d-main-bottom .wrap--event .content--right .par {
    padding-left: 16px;
  }

  .d-main-bottom .wrap--event .content--right .button.calendar {
    margin-left: 16px;
  }
}

@media screen and (max-width: 360px) {
  .d-main-top .left .total-gift .card-block .wrap--info p {
    display: block;
    margin-bottom: 30px;
    font-size: 20px;
    margin-left: 0;
    margin-top: 8px;
  }
}

.events .card-block {
    padding: 42px;
}

.events .event-list a {
    color: black;
    text-decoration: none;
}

.events .event-item .card {
    border: 2px solid #f2f2f2;
    box-shadow: none;
}

.events .event-item .card-header {
    display: flex;
    border-bottom: none;
}

.events .event-item-hover {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    position: relative;
}

.events .event-item-hover:hover {
    opacity: 0.6;
}

.events .event-item-hover .event-img-round {
    border-radius: 50%;
    width: 152px;
    height: 152px;
    display: block;
    overflow: hidden;
    margin: 0 auto;
}

.events .event-item-hover .event-img-round.blank {
    background: #f2f2f2;
}

.events .event-item-hover .event-img-round img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.events .event-item-hover .event-img-round.blank img {
    margin: 20%;
    width: 60%;
    height: 60%;
    object-fit: contain;
}

.events .event-img {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: stretch;
    overflow: hidden;
    max-height: 400px;
    border-radius: 30px;
}

.events .event-img.blank {
    justify-content: center;
    background: #f2f2f2;
    min-height: 200px;
    height: 20vw;
}

.events .event-img > img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: contain;
}

.events .event-img.blank > img {
    width: auto;
    height: auto;
}

.events .event-section {
    margin: 30px;
}

.events .event-attribute {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 420px;
}

.events .event-attribute .item-icon {
    width: 32px;
    height: 32px;
}

.link-icon {
    margin-top: .3rem;
    background-color: var(--swiper-theme-color)
}

.service-time-link-icon {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.events .event-attribute .item-content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
    margin-left: 24px;
}

.events .event-attribute .item-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.events .event-attribute .item-group.edit {
    display: block;
}

.events .event-attribute .item-group .item-label {
    margin: 0px;
}

.events .event-attribute .item-group .item-value {
    flex: 1 1;
    font-weight: bold;
    margin: 0px;
    margin-left: 12px;
    min-width: 0px;
    min-height: 0px;
}

.events .event-attribute .item-group.edit .item-value {
    display: block;
    flex: unset;
    margin: 0px;
}

.events .event-attribute .item-group .item-value .form-control.MuiFormControl-root {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0px;
}

.events .event-attribute .item-group .item-value .form-control .MuiInput-input {
    text-align: center;
    font-size: 18px;
}

.events .event-section div.MuiGrid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.events .event-section .event-attribute.full-width {
    width: 100%;
}

.events .event-section .ml-venue {
    margin-left: 58px;
}

.events .event-section .map-responsive {
    overflow: hidden;
    position: relative;
    height: 280px;
    width: 100%;
    border-radius: 18px;
}

.events .event-share {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.events .event-attendees {
    width: 100%;
}

.events .event-title {
    word-break: break-word;
}

.events .event-card {
    padding: 18px;
}

.events .event-details-title {
    color: #fe9168;
    margin-bottom: 20px;
}

.events .event-days-left {
    color: #fe9168;
}

.events .group-event .event-days-left {
    color: #31c5c3;
}

/* Big Calendar */

.rbc-container .rbc-calendar {
    min-height: 640px;
    margin-bottom: 20px;
}

.rbc-container .rbc-calendar .toolbar-container .rbc-back-next {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
}

.rbc-container .rbc-calendar .toolbar-container .rbc-back-next-button {
    border: none;
    color: #fe9b6e;
    cursor: pointer;
}

.rbc-container .rbc-calendar .toolbar-container .rbc-back-next-button:focus {
    box-shadow: none;
}

.rbc-container .rbc-calendar .toolbar-container .rbc-month-label {
    color: #fe9b6e;
    margin: 0 15px;
}

.rbc-container .rbc-calendar .rbc-month-view {
    border: 2px solid #f2f2f2;
    box-shadow: none;
    border-radius: 30px;
    overflow: hidden;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-month-header .rbc-header {
    height: 48px;
    line-height: 48px;
    background-color: #ededed;
    border-bottom: 2px solid #ededed;
    text-transform: uppercase;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-month-row {
    border-top: 2px solid #ededed;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-header + .rbc-header,
.rbc-container .rbc-calendar .rbc-month-view .rbc-day-bg + .rbc-day-bg {
    border-left: 2px solid #ededed;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-day-bg.rbc-off-range-bg {
    background: #fafafa;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-day-bg.rbc-today {
    background: #ededed;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-date-cell {
    text-align: left;
    margin-top: 8px;
    margin-left: 8px;
}

.rbc-container .rbc-calendar .rbc-month-view .rbc-event:focus {
    outline: 0;
}

.events .event-img-dropzone {
    position: relative;
    width: auto;
    height: auto;
    border: 2px dashed #666666;
    border-radius: 30px;
    overflow: hidden;
}

.reg-link {
    color: blue;
    text-decoration: underline;
}

.reg-link:visited {
    color: darkred;
}

.reg-link:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.item {
    width: 577px;
    height: 498px;
    align-items: flex-start !important;
}

.events .event-img-dropzone > .dropzone-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: #24242486;
    cursor: pointer;
}

.events .event-img-dropzone:hover > .dropzone-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.events .event-img-dropzone:hover > .dropzone-overlay > .overlay-icon {
    display: block;
    width: 40px;
    height: 40px;
}

.events .event-img-dropzone:hover > .dropzone-overlay > .overlay-text {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
}

.events .event-img-dropzone:hover > .dropzone-overlay .remove-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
}

.appended-input {
    border-right: 0
}

.custom-append-text {
    background: transparent;
    border-left: 0;
    font-size: small;
    font-style: italic;
    color: #6c757d;
}

.link-icon {
    height: 32px !important;
    width: 32px !important;
    background-color: var(--swiper-theme-color);
}

#link-value {
    display: flex;
    flex-wrap: wrap;
}

.link-form-group {
    flex-grow: 1
}

.link-form-group-video-conf {
    flex-basis: 75%
}

.link-form-group-video-conf-phone {
    flex-basis: 49%
}

.link-form-group-video-conf-phone-number {
    margin-right: 2%
}

.event-wrap-users {
    margin: auto auto 25px auto;
    width: 80%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.event-wrap-user {
    text-align: center;
    word-break: break-all;
    margin: auto;
    cursor: pointer;
}

.event-wrap-user h6 {
    font-weight: bold;
}

.events .event-section .item-content .form-group .btn-group .dropdown-menu .dropdown-item,
.events .event-section .item-content .form-group .btn-group .dropdown-menu .dropdown-item {
    color: #fff;
}

.events .event-section .item-content .form-group .btn-group .dropdown-menu .dropdown-item.active,
.events .event-section .item-content .form-group .btn-group .dropdown-menu .dropdown-item:active {
    color: #fff;
}

@media screen and (max-width: 650px) {
    .events .card-block {
        padding: 12px;
    }

    .event-wrap-user {
        padding: 0;
        width: 50%;
        height: 50%;
        margin-bottom: 0px;
    }
}

@media screen and
(min-width: 965px) and (max-width: 1046px){
    .event-attribute{
        width: 390px !important;
    }
}

@media screen and (max-width: 360px) {
    .event-wrap-user {
        width: 100%;
        height: 23%;
        display: block;
    }
}

.giving-history .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.giving-history .g-main-history {
  padding-bottom: 42px;
}

.giving-history .g-main-history .table .table-body .table-cell > div {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.giving-history .g-picker-container span.label {
  margin-bottom: 0;
  line-height: 33px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.giving-history .g-picker-container > div {
  width: 100%;
}

.giving-history .g-total-by {
  margin-top: 34px;
}

.giving-history .g-total-by .g-total-by-date {
  border-radius: 30px;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-top {
  border-bottom: 2px solid #ebebeb;
  background: #f5f5f5;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-top p {
  margin: 0;
  padding: 1rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #c1c1c1;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-main {
  background: #f5f5f5;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-main ul {
  padding: 0 36px 0 20px;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-main ul li {
  list-style: none;
  padding: 10px 0;
  color: #4d4d4d;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-main ul li .g-showgiving-detail {
  display: flex;
  justify-content: space-between;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-main ul li .g-showgiving-detail p {
  margin-bottom: 0;
  font-weight: bold;
}

.giving-history .g-total-by .g-total-by-date .g-total-by-date-bottom {
  height: 62px;
  background: #aeaeae;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  color: #fff;
  line-height: 3.5;
  font-weight: bold;
}

.giving-history .g-main-history-table .amount {
  position: relative;
}

.giving-history .g-main-history-table .amount .manual {
  position: absolute;
  right: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #31c5c3;
}

@media screen and (max-width: 720px) {
  .giving-history .g-from .react-date-picker .react-date-picker__calendar--open {
    left: 0;
    right: auto;
  }
  .giving-history .g-to .react-date-picker .react-date-picker__calendar--open {
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 620px) {
  .giving-history .g-date-picker-container {
    display: block;
  }

  .giving-history .g-to .react-date-picker .react-date-picker__calendar--open,
  .giving-history .g-from .react-date-picker .react-date-picker__calendar--open {
    left: auto;
    right: 0;
  }
  .giving-history .react-date-picker .react-date-picker__calendar--above-label {
    bottom: auto;
    top: 100%;
  }
}

@media screen and (max-width: 504px) {
  .giving-history .g-total-by p {
    font-size: 14px;
  }
}

@media screen and (max-width: 386px) {
  .giving-history .g-total-by p {
    width: 46%;
  }
}

@media screen and (max-width: 372px) {
  .giving-history .g-total-by p {
    font-size: 11px;
  }
}

/* New User Modal */

.giving-upload .new-entry-edit span.link {
  color: #007bff;
  cursor: pointer;
}

/*  */
.giving-upload .new-entry-edit .MuiIconButton-root {
  padding: 4px;
}

.giving-upload .new-entry-edit label {
  height: 24px;
  display: block;
}

.giving-upload .new-entry-edit .new-entry-edit-input-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.giving-upload .new-entry-edit .new-entry-edit-input-group {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.giving-upload .new-entry-edit .new-entry-edit-input-group textarea {
  resize: none;
}

.giving-upload .template-table-wrapper {
  margin-left: 48px;
  margin-right: 48px;
}

.giving-upload .template-table-wrapper .button.clear-row {
  position: fixed;
  top: 0;
  margin-left: -48px;
  visibility: hidden;
  transition: 0.5s visibility;
}

.giving-upload .template-table-wrapper .button.clear-row.visible {
  visibility: visible;
}

.giving-upload .template-table-wrapper .button.clear-row:hover {
  visibility: visible;
}

.giving-upload .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.giving-upload .card-block > .card-footer {
  margin: -1.25rem;
  margin-top: -1.25rem;
  margin-top: 1.25rem;
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 575px) {
  .giving-upload .new-entry-edit .new-entry-edit-input-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.g-main-detail-history {
  padding-bottom: 42px;
}

.g-main-detail-history .table .table-body .table-cell > div {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.FaithmoAuth__bottom {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    background: black;
    color: whitesmoke;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FaithmoAuth__bottom .links {
    display: inline-block;
    padding: 0;
    text-align: center;
    align-self: center;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}


.links li {
    display: inline;
}

.links li:after {
    display: inline;
    content: " ";
    letter-spacing: 1em;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwAAADsABataJCQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xMkMEa+wAAAAnSURBVBhXY/Dz89MA4sNA/B9Ka4AEYQIwfBgkiCwAxjhVopnppwEApxQqhnyQ+VkAAAAASUVORK5CYII=) center center no-repeat;
}

.FaithmoAuth__copyright-full {
    align-self: start;
    margin-left: 1rem;
}

.FaithmoAuth__copyright-mobile {
    display: none;
}


@media screen and (max-width: 550px) {
    .FaithmoAuth__copyright-full {
        display: none;
    }

    .FaithmoAuth__copyright-mobile {
        display: inherit;
        align-self: center;
    }

    .spacer{
        display: none;
    }
    .FaithmoAuth__bottom {
        flex-direction: column;
    }
}

/* Header */

.home {
    background: white;
}

.home .header {
    background-repeat: no-repeat;
    background-position-x: 55%;
    background-position-y: center;
    background-size: cover;
}

.home .header a {
    text-decoration: none;
}

.home .header-content-top {
    padding: 32px 23px;
}

.home .header-content-top .button.login-btn {
    float: right;
    font-size: 22px;
    color: #fff !important;
    padding: 8px 64px;
    min-width: 0px;
    height: 58px;
}

.home .header-content-main {
    margin-top: 115px;
}

.home .title--main {
    font-size: 76px;
    letter-spacing: 15px;
    color: #fff;
    font-weight: lighter;
    text-transform: uppercase;
    display: inline-block;
    margin: 0;
    padding: 11px 75px;
    background-color: hsla(0, 0%, 100%, 0.1);
}

.home .title-info--main {
    color: #fff;
    font-weight: normal;
    font-size: 40px;
    letter-spacing: 12px;
    margin: 4px 0 0;
    background-color: hsla(0, 0%, 100%, 0.1);
    padding: 15px 84px 20px;
}

.home .title-info--main span {
    letter-spacing: 0;
}

.home .header-content-bottom {
    padding-left: 79px;
    padding-bottom: 112px;
    margin-top: 76px;
}

.home .header-content-bottom button {
    font-size: 22px;
    font-weight: normal;
    width: 274px;
    height: 72px;
}

.home .button.margin--r {
    margin-right: 27px;
}

.home .button.neutral.header {
    position: absolute;
    right: 23px;
    top: 32px;
}

.header-signup-modal {
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: center;
    background-size: cover;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-signup-modal a {
    font-size: 17px;
    background-color: transparent;
    border: 2px solid #fff;
    padding: 13px 61px;
    margin: 0 30px;
    text-decoration: none;
}

.header-signup-modal a:hover {
    color: #fff;
}

.header-signup-modal .header-title {
    font-family: Libre Baskerville, serif;
    color: #fff;
    font-size: 25px;
    text-align: center;
    font-style: italic;
    font-weight: lighter;
    padding: 0 30px;
    margin: 60px 0;
}

.header-signup-modal .layer--dark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

/* Main */
.home .main {
    background: white;
    margin-bottom: 60px;
}

.home .main-content1 {
    padding: 0 20px;
}

.home .main-title,
.home .main-bottom {
    text-align: center;
}

.home .main-title h1,
.home .main-bottom h1 {
    font-size: 40px;
    font-weight: lighter;
    color: #2b2b2b;
    padding: 50px 20px 20px;
    letter-spacing: 3px;
    margin-bottom: 0;
}

.home .main-title p {
    color: #666666;
    padding: 0 24%;
    letter-spacing: 1px;
    margin-bottom: 0;
}

.home .mainimg {
    text-align: center;
    padding: 38px 0 74px;
}

.home .main-bottom h1 {
    padding: 50px;
    margin-bottom: 0;
}

.home .main-bottom .button {
    font-size: 14px;
    height: auto;
    width: auto;
    padding: 10px 25px;
    letter-spacing: 1px;
    font-weight: normal;
}

.home .main-bottom hr {
    margin: 36px 0;
}

.home .main-bottom p {
    font-size: 25px;
    font-style: italic;
    letter-spacing: 2px;
    margin-bottom: 30px;
    color: rgba(67, 67, 67, 0.76);
}

.home .main-bottom .contact-block {
    border: 3px solid #feb179;
    height: 113px;
    width: 295px;
    margin: 28px 10px 60px;
    padding: 20px;
    display: inline-block;
    border-radius: 25px;
    font-size: 17px;
}

.home .main-bottom .contact-block .contact-text {
    text-transform: uppercase;
    color: #fc9a6d;
    font-weight: bold;
    line-height: 2;
    display: block;
    letter-spacing: 2px;
}

.home .main-bottom .contact-block .contact-value {
    color: #7e7e7e;
    font-weight: bold;
    line-height: 2;
}

/* Available */

.home .Available {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAALJCAMAAACp2AJIAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAnUExURf+MZv+MZv+SbP+Paf+Zcv+iev+lff+Vb/+rg/+ogP+cdP+ed/+uhpzoL/oAAAABdFJOU+YJj58rAAAAvUlEQVRIx+3U3Q6DIAwF4IqUX9//edcWBNyWSDW7WUy+nBy4MSoUAGBZHnorrGRh1OvyAgNmZVxMTTUPnhjO2idYSiuoW15yHnn7ZfNN2vXNBCnVVMiQNXBMlIJSNBxxgMKdiZSxJ4uzAgldDIflp631TUjZfqE9JaiV92p5yo29fE8lPHYmPxHvyfv5KScKlUeRlDOchzzVLoi1s7zcUz/e3On7Po4I7YTp06kMK5lX1yZenZZtcrZ8/BGAF26uGX7ZXq6/AAAAAElFTkSuQmCC) repeat-x;
    position: relative;
    display: flex;
    margin: 0;
}

.home .Available .available-mobile {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.home .Available .available-mobile img {
    margin: 40px;
    max-height: 460px;
    display: block;
}

.home .Available .discription-part {
    padding-left: 88px;
    max-width: 50%;
    margin: 180px 0px;
    display: inline-block;
    vertical-align: top;
}

.home .Available .discription-part h2 {
    font-size: 45px;
    font-weight: normal;
    color: #2b2b2b;
    letter-spacing: 3px;
    margin-bottom: 15px;
}

.home .Available .discription-part .icons-style {
    font-size: 44px;
    color: #fff;
    margin-bottom: 24px;
}

.home .Available .discription-part .icons-style .and-sign {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: lighter;
    font-size: 40px;
}

.home .Available .discription-part p {
    font-size: 21px;
    width: 92%;
}

/* Feature */

.home .Feature .row {
    margin: 0;
}

.home .Feature .feature-mobile {
    text-align: center;
    margin-top: 74px;
}

.home .Feature .feature-mobile img {
    max-height: 460px;
}

.home .Feature .feature-main h2 {
    font-weight: normal;
    color: #2b2b2b;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 36px;
    margin-top: 167px;
}

.home .Feature .feature-main ul {
    list-style: none;
    padding-left: 0;
}

.home .Feature .feature-main ul li {
    display: -webkit-inline-box;
    margin-bottom: 36px;
    line-height: 20px;
}

.home .Feature .feature-main ul li p {
    margin-bottom: 0;
    padding-left: 14px;
    letter-spacing: 1px;
    color: #8f8f8f;
}

/* Footer */

.home .footer {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAFWCAMAAAB0ALxDAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAeUExURf+bdP+rg/+ed/+hev+Ycv+mfv+ogf+uhv+kfP+Wb6p+EvEAAAB2SURBVDjLtdNRDoAgCABQBFG8/4ULMsvcLFq5NwZfTgQoBXyiKRCjG2zxk4MVupAiBNoSDxEgI1PMN1ICThqfCOFwKUe55dlYkv/Qbglu8/fyUGrH+L6rskbRKOJDcvpZUuSFFTrp9IIlNssvjq7UvlYz/eb2FmCGCvUxy4q5AAAAAElFTkSuQmCC);
}

.home .footer .row {
    margin: 0;
}

.home .footer ul {
    margin-bottom: 0;
}

.home .footer .footer-boxes {
    padding: 24px 0px;
}

.home .footer .footer-box {
    width: 100%;
    height: 100%;
    padding: 30px;
    float: left;
    border-right: 2px solid #ffbba2;
}

.home .footer .footer-box-4 {
    border: none;
}

.home .footer .footer-box .title {
    font-size: 25px;
    line-height: 2;
    color: #2b2b2b;
    font-weight: bold;
}

.home .footer .footer-box .discription {
    font-size: 19px;
    color: #fff;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 1px;
}

.home .footer .footer-box .links {
    color: #fff;
    margin-top: 24px;
    display: flex;
}

.home .footer .footer-box .links .social {
    font-size: 30px;
    padding: 13.5px;
    margin-right: 10px;
    min-width: 72px;
    text-align: center;
    background: hsla(0, 0%, 100%, 0.28);
}

.home .footer .footer-box .links .email-text {
    height: 50px;
    width: 100%;
    font-size: 22px;
    padding: 8px 19px;
    border: 1px solid #fff;
}

.home .footer .footer-box .links .email-text span {
    margin-left: 10px;
}

.home .footer .footer-box .links .email-us {
    margin-top: 12px;
}

.home .footer .footer-box .links .email-us span {
    padding: 13px 33px;
    text-transform: uppercase;
    background: hsla(0, 0%, 100%, 0.28);
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 18px;
}

.FaithmoAuth__badges {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.FaithmoAuth__badge {
    margin: 8px;
    height: 48px;
    object-fit: cover;
    width: 9rem;
    border-radius: 1.1rem;
}

.logo img {
    height: 3rem;
    width: 3rem;
}

.summary {
    width: 30%;
    border-left: 1px solid whitesmoke;
    padding-left: 1rem;
}

.footer {
    padding: 1rem 0;
}

.social-media {
    border-right: 1px solid whitesmoke;
    padding-right: 1rem;
    height: 100%;
}

/* Responsive */

@media screen and (max-width: 1206px) {
    .home .main-title p {
        padding: 0 10%;
    }

    .home .Available .discription-part {
        margin: 140px 0px;
        padding-left: 60px;
    }
}

@media screen and (max-width: 992px) {
    .home .footer .footer-box-2 {
        border: none;
    }

    .summary {
        width: 100%;
        padding: 0;
        border: none;
    }

    .social-media {
        padding: 0;
        border: none;
        height: inherit;
    }

}

.privacy-terms-modal article {
    padding: 5rem;
}

@media screen and (max-width: 970px) {
    .home .Available {
        min-height: 600px;
    }
}

@media screen and (max-width: 768px) {
    .home .title--main {
        font-size: 50px;
    }

    .home .title-info--main {
        font-size: 30px;
    }

    .home .header .button.lightblue,
    .home .header .button.red {
        width: 220px;
        height: 62px;
    }

    .home .header-content-bottom .button {
        font-size: 16px;
    }

    .home .Available {
        background-size: auto 1100px;
        display: block;
    }

    .home .Available .available-mobile {
        width: 100%;
        text-align: right;
        padding-right: 0;
    }

    .home .Available .available-mobile img {
        margin-top: 0px;
        margin-bottom: 0px;
        display: block;
    }

    .home .Available .discription-part {
        max-width: 100%;
        margin-top: 90px;
        padding: 0 10%;
        margin-bottom: 50px;
    }

    .home .Feature .feature-main ul li p {
        width: 92%;
    }

    .home .Feature .feature-main h2 {
        text-align: center;
        margin-top: 57px;
        margin-bottom: 50px;
    }

    .home .footer .footer-box {
        border-right: 0;
    }

    .home .footer .footer-boxes {
        border-bottom: 2px solid #ffd5c5;
    }

    .home .footer .footer-boxes:first-child {
        padding: 30px 0 80px;
    }

    .privacy-terms-modal article {
        padding: 2rem;
    }

    .logos{
        width: 75%;
    }
}

.white-font{
    color: white !important;
}

@media screen and (max-width: 686px) {
    .home .main-bottom .contact-block {
        width: 100%;
        margin: 28px 0px 60px;
    }
}

@media screen and (max-width: 660px) {
    .home .title--main {
        font-size: 46px;
        padding: 11px 38px;
    }

    .home .title-info--main {
        font-size: 24px;
        padding: 15px 38px 20px;
    }

    .home .header-content-bottom {
        padding-left: 40px;
    }
}

@media screen and (max-width: 620px) {
    .home .main-title h1,
    .home .main-bottom h1 {
        font-size: 30px;
    }

    .home .main-title p {
        padding: 0;
        font-size: 14px;
    }
}

@media screen and (max-width: 606px) {
    .home .header-signup-modal .header-title {
        line-height: 44px;
    }
}

@media screen and (max-width: 564px) {
    .home .main-bottom h1 {
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 550px) {
    .home .header-content-top .logo {
        width: 130px;
    }

    .home .header-content-top .button.login-btn {
        font-size: 20px;
        padding: 6px 32px;
        height: 48px;
    }

    .home .header-content-bottom .button {
        font-size: 14px;
    }

    .home .header .button.lightblue,
    .home .header .button.red {
        width: 162px;
        height: 52px;
    }
}

@media screen and (max-width: 520px) {
    .home .title-info--main {
        letter-spacing: 8px;
    }
}

@media screen and (max-width: 448px) {
    .home .main-bottom h1 {
        font-size: 30px;
    }
}

@media screen and (max-width: 440px) {
    .home .header {
        background-position-x: 60%;
    }

    .home .title--main {
        font-size: 36px;
        letter-spacing: 10px;
    }

    .home .title-info--main {
        font-size: 20px;
        letter-spacing: 6px;
    }

    .home .header .button.lightblue,
    .home .header .button.red {
        width: 138px;
        height: 52px;
    }

    .home .header-content-bottom .button {
        font-size: 10px;
    }

    .home .button.margin--r {
        margin-right: 12px;
    }
}

@media screen and (max-width: 416px) {
    .home .main-bottom h1 {
        padding: 50px 2px;
    }

    .home .main-bottom p {
        font-size: 18px;
    }
}

@media screen and (max-width: 378px) {
    .home .header-signup-modal a {
        font-size: 14px;
        padding: 13px 25px !important;
    }
}

@media screen and (max-width: 372px) {
    .home .header-content-top {
        padding: 16px 16px;
    }

    .home .header-content-top .logo {
        width: 120px;
    }

    .home .header-content-top .button.login-btn {
        font-size: 16px;
        border: 2px solid #fff;
        padding: 6px 12px;
        height: 40px;
    }

    .home .title--main {
        font-size: 32px;
        letter-spacing: 6px;
    }

    .home .title-info--main {
        font-size: 18px;
        letter-spacing: 4px;
    }

    .home .header-content-bottom {
        padding: 0 20px 112px;
    }

    .home .button.margin--r {
        margin-right: 0;
        margin-bottom: 14px;
    }

    .home .header-content-bottom .button {
        font-size: 14px;
    }

    .home .header .button.lightblue,
    .home .header .button.red {
        width: 100%;
    }

    .home .main-bottom .contact-block .contact-text {
        letter-spacing: 1px;
    }

    .home .Available .discription-part h2 {
        font-size: 38px;
    }
}

.church-info,
.church-contact-info,
.church-social-media,
.church-admin-panel {
  padding: 38px 28px;
  border-bottom: 2px solid #eceded;
}

.church-info-detail,
.church-contact-info-detail {
  padding: 14px 22px 0;
}

.church-info-detail .form-control,
.church-contact-info-detail .form-control,
.church-social-media .form-control,
.church-contact-info-address .contact-info-state,
.church-contact-info-address .contact-info-country {
  height: 50px;
}

.form-text {
  width: 100%;
  line-height: 32px;
  font-size: 16px;
  padding: 0.5rem 8px;
  height: 50px;
  margin: 0;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-text.text-empty {
  font-style: italic;
  color: #6c757d;
}

.w-fixed-label {
  width: 120px;
}

.church-profile .form-label {
  text-align: right;
}

.church-profile .form-control:disabled {
  background: transparent;
  border: none;
  cursor: default;
}

.church-profile .form-control:disabled::-webkit-input-placeholder {
  color: transparent;
}

.church-profile .form-control:disabled::placeholder {
  color: transparent;
}

/* Church Information */

.church-info-detail .church-info-desc {
  display: flex;
  margin-top: 24px;
}

.church-info-detail .church-info-desc .form-control {
  height: 200px;
  padding: 18px 16px;
}

/* Contact Information */
.church-contact-info-phone,
.church-contact-info-address {
  display: flex;
}

.church-contact-info-detail .row {
  width: 100%;
}

.church-contact-info-detail .church-contact-info-address .contact-info-item {
  margin-top: 8px;
  margin-bottom: 8px;
}

.church-contact-info-detail .church-contact-info-address .btn-group .dropdown-toggle.disabled {
  color: #000;
}

/* Social Media */
.church-social-media .form-group {
  position: relative;
  width: 100%;
}

.church-social-media .form-label,
.church-social-media .form-label img {
  width: 50px;
  height: 50px;
  margin: 0 !important;
  padding: 0 !important;
}

.church-social-media .form-text,
.church-social-media .form-control {
  width: auto;
  flex: 1 1;
}

.action-group.profile-action p {
  color: #000;
  font-size: 18px;
  font-style: italic;
  margin-top: 20px;
}

.church-admin-panel .card .card-header .title {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 26px;
  letter-spacing: 1px;
}

.admin-panel-edit span.table-action {
  font-size: 18px;
  color: #31c5c3;
  display: block;
  text-decoration: underline;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

/*  AdminPanelAdd  */
.admin-panel-detail {
  margin: 38px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-panel-detail .search-box {
  max-width: 480px;
}

.admin-panel-detail .apa-selected-user p {
  font-style: normal;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
}

.admin-panel-detail .apa-selected-user .apas_name span {
  border-bottom: 1px solid;
  font-weight: bold;
}

.admin-panel-detail .apa-selected-user .apas_email {
  margin-bottom: 42px;
}

.admin-panel-detail .apa-permission {
  font-size: 18px;
}

.admin-panel-detail .apa-permission p {
  text-transform: uppercase;
  font-weight: bold;
}

.admin-panel-detail .apa-permission .section-title {
  font-style: italic;
  text-decoration: underline;
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 1324px) {
  /* Contact Information */
  .church-info-detail .form-control::-webkit-input-placeholder {
    font-size: 14px;
  }
  .church-info-detail .form-control::placeholder {
    font-size: 14px;
  }
}

@media screen and (max-width: 810px) {
  /* Social Media */
  .church-social-media .form-group .form-control::-webkit-input-placeholder {
    font-size: 14px;
  }
  .church-social-media .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .church-admin-panel .card-block .social-main-table {
    display: block;
    overflow-x: auto;
  }

  .church-admin-panel .card-block tbody tr td:first-child {
    min-width: 194px;
  }

  .church-admin-panel .card-block .table-email {
    min-width: 239px;
  }

  .church-admin-panel .card-block .table-group {
    min-width: 132px;
  }
}

@media screen and (max-width: 767px) {
  .form-label {
    text-align: left;
  }

  /* Church Information Responsive */
  .church-info-detail .church-info-desc {
    display: block;
  }

  /* Contact Information */
  .church-contact-info-phone,
  .church-contact-info-address {
    display: block;
  }

  /* Social Media */
  .church-social-media .form-group {
    padding: 0 22px;
  }

  .church-social-media .form-group:first-child {
    margin-bottom: 16px;
  }

  .church-social-media .form-group .form-control::-webkit-input-placeholder {
    font-size: 16px;
  }

  .church-social-media .form-group .form-control::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 434px) {
  /* Social Media */
  .church-social-media .form-group .form-control::-webkit-input-placeholder {
    font-size: 14px;
  }
  .church-social-media .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .church-social-media .form-group {
    padding: 0;
  }

  .admin-panel-detail {
    margin: 0px;
  }
}

@media screen and (max-width: 396px) {
  /* Church Information Responsive */
  .church-info-detail,
  .church-contact-info-detail {
    padding: 14px 0px;
  }

  .church-info-detail .row,
  .church-info-detail .church-info-desc {
    display: block;
  }

  .church-info-detail .church-info-desc {
    margin-top: 0;
  }

  /* Social Media */
  .church-social-media .form-group .form-control::-webkit-input-placeholder {
    font-size: 11px;
  }
  .church-social-media .form-group .form-control::placeholder {
    font-size: 11px;
  }

  .action-group.profile-action p {
    font-size: 16px;
  }

  .church-admin-panel .card .card-header .title {
    font-size: 14px;
  }

  /*  AdminPanelAdd  */
  .admin-panel-detail .apa-permission {
    font-size: 16px;
  }
}

.service-times-wrapper {
  margin: -1.25em;
}

.service-times-wrapper .st-dropdown {
  display: flex;
  align-items: center;
  padding: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.service-times-wrapper .st-dropdown .st-show {
  margin-bottom: 0;
  line-height: 34px;
  margin-right: 10px;
}

.service-times-wrapper .form-control {
  height: 50px;
  text-align: center;
  border-radius: 0;
}

.service-times-wrapper .form-control.error,
.service-times-wrapper .error .form-control,
.service-times-wrapper .time-picker.error {
  border: 1px solid rgba(255, 0, 0, 0.65);
}

.service-times-wrapper .time-picker {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  height: 50px;
}

.service-times-wrapper .time-picker input {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
}

.service-times-wrapper .time-picker > div {
  color: #464a4c !important;
  margin: auto;
}

.service-times-wrapper .time-picker hr {
  display: none;
}

.service-times-wrapper .service-block.card {
  position: relative;
  padding: 0px 32px 32px 32px;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #f2f2f2;
}

.service-times-wrapper .service-block.card > .card-header {
  border: none;
}

.service-times-wrapper .service-block.card > .card-header .title-buttons {
  padding: 0px;
}

.service-times-wrapper .service-block.card > .card-header .title-buttons .button {
  min-width: 140px;
  margin: 4px;
}

.service-times-wrapper .service-block.card > .card-body {
  border: 2px solid #f2f2f2;
  border-radius: 30px;
  box-shadow: none;
  padding: 0px;
}

.service-times-wrapper .service-block .st-name {
  width: 36%;
  max-width: 36%;
  text-align: center;
  padding: 0 20px;
  min-width: 360px;
}

.service-times-wrapper .service-block .st-name .form-control {
  text-align: left;
}

.service-times-wrapper .service-block .st-start-time,
.service-times-wrapper .service-block .st-end-time {
  width: 20%;
  max-width: 20%;
  text-align: center;
  padding: 0 20px;
  min-width: 160px;
}

.service-times-wrapper .service-block .st-action {
  width: 24%;
  max-width: 24%;
  text-align: center;
  min-width: 200px;
}

.service-times-wrapper .service-block .st-action button {
  margin: 4px;
}

.service-times-wrapper .service-block .card-block {
  padding: 20px 0;
}

.service-times-wrapper .service-block .card-block p {
  font-style: italic;
  font-size: 18px;
  color: #adadad;
  padding-left: 46px;
  padding-right: 46px;
}

.service-times-wrapper .service-block .table-header {
  display: flex;
  border-bottom: none;
}

.service-times-wrapper .service-block .table-header .st-name,
.service-times-wrapper .service-block .table-header .st-start-time,
.service-times-wrapper .service-block .table-header .st-end-time,
.service-times-wrapper .service-block .table-header .st-action {
  text-align: center;
  border-left: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  padding: 12px;
}

.service-times-wrapper .service-block .table-header .st-name {
  border-left: none;
}

.service-times-wrapper .service-block .service-item {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.service-times-wrapper .service-block .service-item .helper-text {
  display: block;
}

.service-times-wrapper .service-block .service-item .item-cell,
.service-times-wrapper .service-block .service-item .time-picker input,
.service-times-wrapper .service-block .service-item .input-box input {
  padding: 0px 10px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.service-times-wrapper .service-block .service-item .item-cell {
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
}

.service-times-wrapper .service-block.card input.form-control::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
}

.service-times-wrapper .service-block.card input.form-control::placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
}

@media screen and (max-width: 574px) {
  .service-times-wrapper .st-update-btn .st-update {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
  }
}

.gallery-wrapper {
  padding: 52px 15%;
}

.gallery-wrapper .gallery {
  position: relative;
}

.gallery-wrapper .gallery .gallery-add-btn {
  position: absolute;
  top: auto;
  bottom: 6px;
  left: 0;
  padding: 0;
  -webkit-transform: translateX(calc(-100% - 10px));
          transform: translateX(calc(-100% - 10px));
  width: 88px;
  min-width: 88px;
  height: 68px;
  border-radius: 20px;
  font-size: 30px;
}

.gallery-wrapper .gallery .gallery-add-btn img {
  width: 22px;
  vertical-align: inherit;
  margin-right: 10px;
}

.gallery-wrapper .gallery .image-gallery button:focus {
  box-shadow: none;
}

.gallery-wrapper .image-gallery-index {
  top: inherit;
  right: 46%;
  bottom: 20px;
  color: #fe9168;
  background: none;
  font-style: italic;
  font-weight: bold;
}

.gallery-wrapper .image-gallery-left-nav,
.gallery-wrapper .image-gallery-right-nav {
  font-size: 56px;
}

.gallery-wrapper .image-gallery-left-nav::before,
.gallery-wrapper .image-gallery-right-nav::before {
  color: #fe9873;
  text-shadow: none;
}

.gallery-wrapper .image-gallery-left-nav:focus,
.gallery-wrapper .image-gallery-right-nav:focus {
  box-shadow: inherit;
}

.gallery-wrapper .image-gallery-left-nav {
  left: -70px;
}

.gallery-wrapper .image-gallery-right-nav {
  right: -70px;
}

.gallery-wrapper .image-gallery-thumbnails-container {
  text-align: left;
  height: 62px;
}

.gallery-wrapper .image-gallery-thumbnails-container > button {
  width: 68px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.gallery-wrapper .image-gallery-thumbnails-container > button img {
  width: 100%;
  height: 62px;
  object-fit: contain;
}

.gallery-wrapper .image-gallery-thumbnails-container > button > .image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.gallery-wrapper .image-gallery-thumbnails-wrapper {
  width: 100%;
}

.gallery-wrapper .image-gallery-slide-wrapper {
  margin-bottom: 20px;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slides,
.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
  height: 420px;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide > div .image-gallery-image {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.gallery-wrapper .gallery .remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 340px;
  }

  .gallery-wrapper .image-gallery-thumbnail.active {
    border: 4px solid #337ab7;
  }

  .gallery-wrapper .gallery .gallery-add-btn {
    position: relative;
    width: 100%;
    height: 48px;
    -webkit-transform: none;
            transform: none;
    left: auto;
    bottom: auto;
  }
  .gallery-wrapper .gallery .remove-btn {
    right: 40% !important;
  }
}

@media screen and (max-width: 634px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 320px;
  }

  .gallery-wrapper .image-gallery-right-nav {
    right: -52px;
  }

  .gallery-wrapper .image-gallery-left-nav {
    left: -52px;
  }

  .gallery-wrapper .gdeletebtn {
    padding-top: 5px;
    font-size: 16px;
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 540px) {
  .gallery-wrapper .image-gallery-slide-wrapper .image-gallery-slide {
    height: 260px;
  }
}

@media screen and (max-width: 354px) {
  .gallery-wrapper .image-gallery-left-nav,
  .gallery-wrapper .image-gallery-right-nav {
    font-size: 36px;
  }

  .gallery-wrapper .image-gallery-right-nav {
    right: -46px;
  }

  .gallery-wrapper .image-gallery-left-nav {
    left: -46px;
  }
}

.gift-settings {
  padding: 70px 28px;
}

.gs-payments {
  margin-bottom: 62px;
}

.gs-payments .card-header,
.gs-payments .card-block {
  text-align: center;
}

.gs-payments .card-header .title p {
  margin-left: 0;
  padding: 0 16px;
}

.gs-payments .card-block {
  padding: 30px 74px 46px;
}

.gs-payments .pgs-detail .red-large-text {
  margin-bottom: 38px;
}

.gs-payments .pgs-detail .red-large-text:first-child {
  margin-bottom: 0;
}

.gift-wrapper .gift-add {
  text-align: right;
  margin-bottom: 24px;
}

.gift-wrapper .card-header {
  display: flex;
}

.gift-wrapper .card-header .right-title {
  padding: 0.907rem 1.25rem;
}

.gift-wrapper .card-header .title,
.gift-wrapper .card-block .go-detail {
  position: relative;
  flex: auto;
  width: 80%;
}

.gift-wrapper .card-header .right-title,
.gift-wrapper .card-block .go-as {
  width: 20%;
  text-align: center;
}

.gift-wrapper .card-header .title::after {
  content: '';
  background: #f2f2f2;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0px;
}

.gift-wrapper .card-block .go-detail::after {
  content: '';
  background: #f2f2f2;
  width: 2px;
  min-height: 252px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

.gift-wrapper .card > .card-block {
  display: flex;
  padding: 0;
}

.gift-wrapper .card-header .title p {
  margin-left: 36px;
}

.gift-wrapper .go-detail ul,
.gift-wrapper .card-block .go-as ul {
  padding-left: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.gift-wrapper .go-detail ul li,
.gift-wrapper .card-block .go-as ul li {
  list-style: none;
  color: #4d4d4d;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 36px;
}

.gift-wrapper .card-block .go-as ul li {
  padding: 11px 0;
  justify-content: center;
}

.gift-wrapper .gift-option {
  margin-bottom: 40px;
}

.gift-wrapper .go_active_wrapper {
  display: flex;
}

.gift-wrapper .go-detail ul li button {
  width: 40px;
  height: 42px;
  line-height: 1;
  border: none;
  background: #fe9e6f;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.gift-wrapper .go-detail ul li span {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gift-wrapper .go-detail ul li button + span {
  padding-left: 8px;
}

/* Responsive */
@media screen and (max-width: 920px) {
  .gs-payments .card-block {
    padding: 30px 40px 46px;
  }

  .gift-wrapper .card-header .title,
  .gift-wrapper .card-block .go-detail {
    width: 70%;
  }

  .gift-wrapper .card-header .right-title,
  .gift-wrapper .card-block .go-as {
    width: 30%;
  }
}

@media screen and (max-width: 776px) {
  .gift-wrapper .card > .card-header {
    flex-direction: row;
  }

  .gift-settings {
    padding: 0px;
  }
}

@media screen and (max-width: 641px) {
  .gift-wrapper .card-header .title {
    text-align: center;
  }

  .gift-wrapper .card-header .title p {
    margin-left: 12px;
  }

  .gift-wrapper .card-header .title,
  .gift-wrapper .card-block .go-detail {
    width: 60%;
  }

  .gift-wrapper .card-header .right-title,
  .gift-wrapper .card-block .go-as {
    width: 40%;
  }

  .gift-wrapper .card-header .right-title p,
  .gift-wrapper .card-block .go-as p,
  .gift-wrapper .inactive-gift-option .card-header .title p {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 526px) {
  .gift-settings .gift-add > .button.gray {
    display: block;
    width: 100%;
  }

  .gift-wrapper .card-header p,
  .gift-wrapper .card-block .go-as p {
    font-size: 14px;
  }

  .gift-wrapper .go-detail ul li {
    padding: 0px 8px;
  }
}

@media screen and (max-width: 450px) {
  .gs-payments .card-block {
    padding: 30px 20px 46px;
  }
}

@media screen and (max-width: 370px) {
  .gs-payments .card-header .title p {
    line-height: 22px;
    padding-top: 10px;
  }

  .gift-wrapper .inactive-gift-option .card-block .go-detail {
    padding-left: 10px;
  }

  .gift-wrapper .inactive-gift-option .card-header .title p {
    margin-left: 18px;
  }
}

.membership_wrapper {
  padding: 90px 90px 28px;
}

.membership-wrapper2 {
  padding: 60px 120px 60px;
}

.membership_detail ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.membership_detail ul li {
  display: flex;
  align-items: center;
  height: 64px;
  border-top: 2px solid #f2f2f2;
}

.membership_detail ul li:last-of-type {
  border-bottom: 2px solid #f2f2f2;
}

.membership_detail ul li p {
  text-align: left;
  width: 100%;
  margin-bottom: 0;
}

.membership_detail ul li p:first-of-type {
  font-weight: bold;
  text-transform: uppercase;
}

.membership_detail ul li p:last-of-type {
  text-align: right;
}

.button.btn-activate {
  padding: 10px 30px;
  width: auto;
  height: auto;
}

.membership-wrapper2 .membership_note {
  padding: 30px;
  border: 2px solid #fe9168;
  border-radius: 4px;
}

.membership-block .membership-select-header {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}

.membership-block .btn-group {
  position: relative;
  width: 320px;
}

.membership-block .btn-group .dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.action-group.membership-action p {
  margin-top: 50px;
  margin-bottom: 0;
}

.action-group.membership-action span {
  font-size: 14px;
  color: #fe9168;
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 1px;
  margin-bottom: 0;
  cursor: pointer;
}

/* Membership Edit */
.membership-edit .form-control {
  height: 50px !important;
}

.membership-edit .form-group label,
.membership-edit p.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 36px;
}

.membership-edit .card-header .title p {
  margin-left: 48px;
}

.membership-edit .card-body {
  padding: 52px 80px;
  min-height: 240px;
  position: relative;
}

.membership-edit .card-body .card-block {
  padding: 32px 0 0 0;
}

.membership-edit .card-choose ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.membership-edit .card-choose ul li p {
  margin-bottom: 0;
}

.membership-edit .card-choose ul li {
  border-top: 2px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.membership-edit .card-choose ul li:last-child {
  padding-top: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.membership-edit .card-choose li label {
  font-weight: bold;
  text-transform: uppercase;
}

.membership-edit .card-detail {
  margin-top: 40px;
}

.membership-edit .card-detail .btn-group .btn {
  height: 50px;
  font-style: italic;
  color: #3d3d3d;
  border-radius: 0;
}

.membership-edit .securityCode {
  line-height: 50px;
  text-decoration: underline;
  letter-spacing: 1px;
  color: #fe9169;
}

/* -- radio Button style */
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #3d3d3d;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #c2c2c2;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fe8462;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 1190px) {
  .membership-wrapper2 {
    padding: 40px 80px 40px;
  }
}

@media screen and (max-width: 860px) {
  .membership-wrapper2 {
    padding: 30px 60px 30px;
  }
}

@media screen and (max-width: 768px) {
  /* MemberShip Edit */
  .membership-edit #first_name {
    margin-bottom: 16px;
  }
  .membership_wrapper {
    padding: 40px 0px 28px;
  }
}

@media screen and (max-width: 700px) {
  .membership-edit .card-body {
    padding: 52px 40px;
  }
}

@media screen and (max-width: 598px) {
  .membership-edit .card-choose ul li:first-child {
    display: block;
    height: auto;
    line-height: 54px;
  }

  .membership-edit .card-choose ul li:first-child p {
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 590px) {
  .membership_detail ul li .currentpm {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 549px) and (min-width: 481px), (max-width: 465px) {
  .membership_detail ul li .subPackage {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 500px) and (min-width: 481px),
  (max-width: 416px) and (min-width: 380px),
  (max-width: 341px) {
  .membership_detail ul li .autoRD {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .membership-edit .card-body {
    padding: 52px 20px;
  }

  .membership-edit .form-group label,
  .membership-edit p.title {
    font-size: 16px;
  }
}

@media screen and (max-width: 380px) {
  .membership_detail ul li p {
    font-size: 14px;
  }
}

a[href^="mailto"]{
  color: blue;
  text-decoration: underline;
}

a[href^="mailto"]:hover{
  text-decoration: none;
}
@media screen and (max-width: 676px) {
  .settings .card-tabs #servicetimes-tab,
  .settings .card-tabs #giftsettings-tab {
    line-height: 16px;
    padding-top: 16px;
  }
}

@media screen and (max-width: 550px) {
  .settings .card-tabs #servicetimes-tab,
  .settings .card-tabs #giftsettings-tab {
    line-height: 34px;
    padding-top: 8px;
  }
}

.signup .back {
    top: -30px;
}

.MuiInput-underline:after {
    border-bottom: black !important;
}

.signup_form {
    width: 860px;
    min-height: 680px;
    padding: 24px 87px;
    background-color: #fff;
    border-radius: 50px;
    margin: auto;
    position: relative;
    margin-top: 81px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup .wrap--step-num {
    text-align: center;
    padding-bottom: 22px;
}

.wrap--step-num .list-num:first-child {
    margin-left: 0px;
}

.wrap--step-num .list-num {
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 1px solid #ff8361;
    border-radius: 4em;
    margin-left: 32px;
    cursor: default;
    -webkit-user-select: none; /* Chrome all / Safari all */ /* Firefox all */ /* IE 10+ */
    user-select: none;
}

.wrap--step-num .list-num p {
    font-size: 16px;
    line-height: 19px;
    padding-left: 1px;
    color: #ff8361;
    margin: 0;
}

.wrap--step-num .title--step-num {
    color: #ff8361;
    font-size: 14px;
    margin-top: 9px;
}

.signup .signup_form .form-container {
    flex: 1 1;
}

.signup .signup_form .form-container .signup_form--bottom {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.signup_form--bottom .wrap--signup-img {
    width: 55%;
    display: block;
    margin-top: 20px;
    align-self: flex-start;
}

.signup_form--bottom .wrap--signup-img .image-gallery .image-gallery-slides > .image-gallery-slide {
    background: transparent;
    -webkit-transform: none !important;
            transform: none !important;
    opacity: 0 !important;
    transition: opacity 1000ms ease !important;
}

.signup_form--bottom .wrap--signup-img .image-gallery .image-gallery-slides > .image-gallery-slide.center {
    opacity: 1 !important;
    transition: opacity 1000ms ease !important;
}

.signup_form--bottom .wrap--signup-img .image-gallery-image {
    height: 380px;
    width: auto;
    max-width: 240px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.signup_form--bottom .wrap--signup-img .image-gallery-image img {
    max-height: 380px;
    max-width: 240px;
    object-fit: contain;
    margin: auto;
    display: block;
}

.signup_form--bottom .wrap-form {
    width: 45%;
    display: block;
}

.signup_form--bottom .wrap-form .step1 {
    padding-top: 34px;
}

.signup_form--bottom .wrap-form .steps_input {
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 15px;
    /* height: 50px !important; */
}

.signup_form--bottom .wrap-form .button--signup-step {
    position: relative;
    width: 174px;
    height: 40px;
    margin-top: 30px;
}

/* Step 2 */
.signup_form--bottom .step2 .su-state .su-state-select,
.signup_form--bottom .step2 .su-country .su-country-select {
    height: 33px;
    width: 100%;
}

/* .signup_form--bottom .wrap-form .su-state, .signup_form--bottom .wrap-form .su-country{
  width: 50%;
  margin-left: 7px;
} */
.signup_form--bottom .wrap-form .su-state-select,
.signup_form--bottom .wrap-form .su-country-select,
.signup_form--bottom .su-ques-select {
    color: rgb(135, 135, 135);
}

.signup_form--bottom .su-ques-select {
    margin-top: 16px;
}

.signup_form--bottom .su-state-select em,
.signup_form--bottom .su-country-select em,
.signup_form--bottom .su-ques-select em {
    font-style: normal;
}

.signup_form--bottom .wrap-form .MuiSelect-select-21,
.signup_form--bottom .wrap-form .MuiSelect-select-21 {
    padding-bottom: 1px;
}

/* Thanks */
.thanks {
    text-align: center;
    padding: 0 40px;
}

.thanks h3 {
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
    font-weight: lighter;
    font-size: 24px;
}

.thanks p {
    font-size: 14px;
}

.thanks .thx-text {
    margin-bottom: 86px;
}

.thanks p:first-child {
    margin-bottom: 0;
    margin-top: 28px;
}

/* Responsive */
@media screen and (max-width: 880px) {
    .signup {
        padding: 0 50px;
    }

    .signup_form {
        width: 100%;
        padding: 16px 36px;
        min-height: 540px;
    }

    .thanks {
        padding: 0px 24px;
    }

    .signup_form--bottom .wrap-form .button.gradient {
        height: 46px;
        font-size: 14px;
        width: 168px;
    }

    .signup .signup_form .form-container .signup_form--bottom {
        flex-direction: column;
    }

    .signup_form--bottom .wrap--signup-img {
        height: 190px;
        width: 100%;
        margin: auto;
    }

    .signup_form--bottom .wrap--signup-img .image-gallery-image {
        height: 190px;
    }

    .signup_form--bottom .wrap--signup-img .image-gallery-image img {
        max-height: 190px;
        max-width: 120px;
    }

    .signup_form--bottom .wrap-form {
        width: 100%;
        padding: 0 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    .signup_form--bottom .selecthalf {
        text-align: left;
    }

    .signup_form--bottom .step2 .su-state .su-state-select,
    .signup_form--bottom .step2 .su-country .su-country-select {
        text-align: left;
    }

    .signup_form--bottom .wrap-form .lastName .MuiInput-root-15 {
        width: 100%;
    }

    .signup_form--bottom .wrap-form .su-ques {
        text-align: left;
    }

    .thanks h3 {
        line-height: 40px;
    }
}

@media screen and (max-width: 540px) {
    .signup {
        padding: 0 10px;
    }

    .thanks {
        padding: 0px 8px;
    }

    .signup_form--bottom .selecthalf,
    .signup_form--bottom .wrap-form .steps_input {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 380px) {
    .signup_form--bottom .wrap-form .su-ques {
        font-size: 13px !important;
    }

    .thanks {
        padding: 0px;
    }

    .signup_form--bottom .wrap-form {
        padding: 0 10px;
    }
}

.user-management .filter-box {
  width: 100%;
}
.user-management .filter-box .MuiFormControl-root {
  width: 100%;
}

.user-management .search-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.user-management .table-member {
  position: relative;
}

.user-upload .template-table-wrapper {
  margin-left: 48px;
  margin-right: 48px;
}

.user-upload .template-table-wrapper .icon-button {
  height: 32px;
  width: 32px;
  font-size: 16px;
  line-height: 32px;
}

.user-upload .template-table-wrapper .table .table-header .table-cell {
  text-align: left;
}

.user-upload .template-table-wrapper .table .table-body .table-cell {
  padding: 0;
}

.user-upload .template-table-wrapper .table .table-body .table-cell .has-error {
  background-color: #ffa3b6;
}

.user-upload .template-table-wrapper .table .table-body .table-cell input {
  width: 100%;
  background: inherit;
  border: none;
  padding: 4px 8px;
  margin: 0;
  height: 40px;
}

.user-upload .template-table-wrapper .table .table-body .table-cell .has-error input {
  background-color: #ffa3b6;
}

.user-upload .template-table-wrapper .table .table-row > .table-cell {
  min-height: unset;
}

.user-upload .template-table-wrapper .button.clear-row {
  position: fixed;
  top: 0;
  margin-left: -48px;
  visibility: hidden;
  transition: 0.5s visibility;
}

.user-upload .template-table-wrapper .button.clear-row.visible {
  visibility: visible;
}

.user-upload .template-table-wrapper .button.clear-row:hover {
  visibility: visible;
}

.user-upload .action-buttons {
  display: flex;
  align-items: center;
  height: 80px;
}

.user-upload .upload-wrapper {
  border: 2px dotted #777;
  padding: 48px;
}

.user-upload .upload-wrapper .description-text {
  color: #777;
}

.user-upload .upload-wrapper .link {
  text-transform: uppercase;
}

.user-upload .upload-wrapper .upload-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;
}

.user-upload .submit-wrapper {
  margin-bottom: 24px;
}

.user-upload .submit-wrapper .errors-wrapper {
  max-width: 80px;
  text-align: center;
}

.user-upload .submit-wrapper .error-cnt {
  width: 42px;
  height: 42px;
  text-align: center;
  background-color: #ffa3b6;
  border: 2px solid #d7d7d7;
  border-radius: 8px;
  font-size: 22px;
  font-weight: bold;
  line-height: 42px;
  box-sizing: content-box;
  margin: auto;
}

.user-upload .submit-wrapper .switch-check-box {
  margin-top: 32px;
}

.up-detail {
  background-color: #fff;
  border-radius: 30px;
  margin-top: 64px;
  margin-bottom: 32px;
}

.up-detail .item-label {
  float: right;
}

.up-detail .back {
  top: 30px;
  left: 32px;
}

.up-detail .back {
  color: #31c5c3;
}

.up-detail .unsubscribe {
  position: absolute;
  right: 32px;
  top: 29px;
  text-transform: uppercase;
  border-bottom: 1px solid #31c5c3;
  color: #31c5c3;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; /* Likely future */
}

.up-detail .unsubscribe p {
  margin-bottom: 0;
}

.up-detail .avatar {
  text-align: center;
}

.up-detail .avatar .member-status-detail {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 46px;
}

.up-detail .avatar .check {
  width: 24px;
  vertical-align: text-bottom;
}

.up-detail .up-prop {
  padding-bottom: 40px;
  border-bottom: 1px dashed #afafaf;
}

.up-detail .up-prop .up-prop-left {
  padding: 0 10%;
}

.up-detail .honored_member,
.up-detail .up-notes {
  padding: 42px 53px;
  display: flex;
  flex-wrap: inherit;
  border-bottom: 1px dashed #afafaf;
}

.up-detail .honored_member_detail {
  margin-left: 16px;
}

.up-detail .honored_member_detail p {
  margin-bottom: 0;
}

.up-detail .honored_member_detail .hm-title {
  font-weight: bold;
  text-transform: uppercase;
}

.up-detail .honored_member_detail .hm-detail,
.up-detail .up-notes .notes-detail {
  font-size: 14px;
  padding-top: 4px;
  color: #a4a4a4;
}

.up-detail .up-notes {
  padding: 42px 53px;
  display: block;
  border-bottom: 1px dashed #afafaf;
}

.up-detail .up-notes p {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
}

.up-detail .up-editprofilebtn {
  text-align: center;
  padding: 46px 0;
}

.up-detail .up-editprofilebtn .button.neutral {
  padding: 14px 49.6px;
}

.up-detail .up-groups {
  display: block;
  margin-bottom: 36px;
}

.up-detail .up-groups .card-block,
.up-detail .up-bottomCard .card .card-block {
  padding: 0px 0.25rem;
  min-height: 120px;
}

.up-detail .up-groups .up-groups-detail {
  padding: 32px 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.up-detail .up-groups .up-groups-detail .groups-item {
  width: 33%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-detail .up-groups .up-groups-detail .member-detail {
  display: block;
  margin-left: 18px;
}

.up-detail .up-groups .up-groups-detail .member-detail > p {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 4px;
  text-align: left;
}

.up-detail .up-groups .up-groups-detail .member-detail > p.name {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
}

.up-detail .up-groups .swiper-pagination {
  bottom: 6px;
}

.up-detail .up-bottomCard {
  align-items: stretch;
  justify-content: space-around;
}

.up-detail .up-bottomCard .up-gifts,
.up-detail .up-bottomCard .up-prayer {
  padding: 15px;
}

.up-detail .up-bottomCard .card {
  height: 100%;
}

.up-detail .up-bottomCard .card .card-block .swiper-slide {
  display: flex;
  align-items: center;
}

.up-detail .up-bottomCard .up-giftsInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  width: 78%;
  margin: 0 auto;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p {
  margin-bottom: 0;
}

.up-detail .up-bottomCard .up-giftsInfo .left-pane p.up-giftsDate {
  font-weight: normal;
  font-size: 16px;
}

.up-detail .up-bottomCard .up-giftsInfo .price {
  margin-left: auto;
}

.up-detail .up-bottomCard .up-prayerItem {
  padding: 32px 48px;
  margin: 0;
}

.up-detail .up-bottomCard .up-prayerItem a {
  color: inherit;
}

.up-detail .up-bottomCard .up-prayerItem p {
  margin-bottom: 0;
}

.up-detail .up-bottomCard .up-prayerItem .up-Pdetail {
  font-size: 14px;
}

.up-detail .upe-prop .upe-prop-left .form-group {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.up-detail .upe-prop .upe-prop-right {
  margin-top: auto;
}

.up-detail .upe-prop .upe-prop-right label {
  font-weight: bold;
}

.up-detail .upe-prop .upe-prop-right .upe-state {
  width: 100%;
  margin-bottom: 8px;
}

.up-detail .upe-prop .upe-prop-right .upe-state .dropdown-item {
  margin-bottom: 0;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn {
  width: 100%;
  text-align: left;
  font-size: 14px;
  height: 34px;
  border-radius: 0;
  padding-left: 12px;
  color: #464a4c;
  font-size: 1rem;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn::after {
  display: none;
}

.up-detail .upe-prop .upe-prop-right .upe-stateBtn .entypo-down-open-big::before {
  top: 4px;
}

.up-detail .upe-prop-bottom {
  margin-top: 60px;
  padding-left: 71.797px;
}

.up-detail .upe-b-member {
  margin-top: 40px;
  margin-bottom: 40px;
}

.up-detail .upe-b-member .upe-b-ques {
  display: inline-flex;
  float: left;
}

.up-detail .upe-b-member .upe-b-ques p {
  padding-right: 8px;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
}

.up-detail .upe-b-member .upe-b-radio-group {
  display: flex;
  margin-left: auto;
  max-width: 360px;
}

.up-detail .upe-b-member .upe-b-joined-date {
  margin-left: auto;
  max-width: 340px;
}

.up-detail .upe-b-radio-group .upe-b-radio-btn {
  margin: 0 20px;
}

.up-detail .upe-b-radio-group label {
  text-transform: uppercase;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:before,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:before {
  border: 2px solid #ff9f78;
}

.up-detail .upe-b-radio-group [type='radio']:checked + label:after,
.up-detail .upe-b-radio-group [type='radio']:not(:checked) + label:after {
  width: 12px;
  height: 12px;
  top: 5px;
  left: 5px;
}

.up-detail .upe-b-member .upe-b-notes {
  margin-top: 28px;
}

.up-detail .upe-b-member .upe-b-notes .form-control {
  margin: 0 15px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.up-detail .upe-b-savebtn {
  text-align: center;
}

.unsubscribe {
  display: none;
}

.address-control {
  margin-top: 15px;
}

@media screen and (max-width: 1400px) {
  .up-detail {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (max-width: 1280px) {
  .up-detail .up-prop .up-prop-left {
    padding: 0 0 0 5%;
  }
}

@media screen and (max-width: 991px) {
  .up-detail .up-prop {
    margin-left: 0;
    margin-right: 0;
  }

  .up-detail .up-prop .up-prop-left,
  .up-detail .up-prop .up-prop-right {
    padding: 0;
  }

  .up-detail .honored_member {
    padding-left: 0;
  }

  .up-detail .upe-prop .upe-prop-left,
  .up-detail .upe-prop .upe-prop-right {
    padding-left: 0;
  }

  .up-detail .upe-prop .upe-prop-left {
    margin-bottom: 10px;
  }

  .upe-prop {
    margin: 0;
  }

  .up-detail .upe-prop-bottom {
    padding-left: 0;
  }

  .up-detail .up-groups .up-groups-detail {
    display: block;
  }

  .up-detail .up-groups .up-groups-detail .groups-item {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .up-detail .upe-b-radio-group .upe-b-radio-btn {
    margin: 0px 10px;
  }
  .up-prop-right .homephone .Phone p {
    line-height: 40px;
  }
}

@media screen and (max-width: 768px) {
  .up-detail .honored_member {
    display: block;
    padding-right: 0;
  }

  .honored_member_logo {
    text-align: center;
    margin-bottom: 10px;
  }

  .up-detail .honored_member_detail {
    margin-left: 0;
  }

  .up-detail .up-notes {
    padding: 42px 0;
  }

  .up-detail .honored_member,
  .up-detail .up-notes {
    margin: 0;
    text-align: center;
  }

  .up-detail .upe-b-member {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .up-detail .upe-b-member .upe-b-ques {
    float: none;
    margin-bottom: 20px;
  }

  /* .up-detail .upe-b-member .upe-b-radio-group {
    float: none;
  } */
}

@media screen and (max-width: 640px) {
  .up-detail .back button,
  .up-detail .unsubscribe p {
    font-size: 14px;
  }

  .up-detail .upe-b-member .upe-b-ques p {
    font-size: 15px;
  }

  .up-detail .unsubscribe {
    top: 35px;
    font-weight: inherit;
  }

  .up-detail {
    padding: 16px;
  }
}

@media screen and (max-width: 599px) {
  .up-detail .item-label {
    float: left;
  }
}
@media screen and (max-width: 430px) {
  .up-detail .unsubscribe {
    right: 10px;
    letter-spacing: 0;
  }

  .up-detail .back {
    left: 20px;
    letter-spacing: 0;
  }

  .up-detail .up-editprofilebtn .button.neutral {
    padding: 10px 22px;
    font-size: 14px;
  }

  .up-detail .upe-b-savebtn .button {
    padding: 12px 18px;
    font-size: 14px;
  }

  .up-detail .up-bottomCard .up-giftsInfo {
    display: block;
    text-align: center;
  }
}

.prayers div.tab-content {
  overflow: auto;
}

.prayer-detail > .card > .card-block {
  padding: 0px;
}

.prayer-detail .prayer-section-header {
  color: #fe9168;
}

.prayer-detail .prayer-attrs {
  padding: 48px;
  border-bottom: 2px solid #f2f2f2;
}

.prayer-detail .navigation-pane {
  margin-bottom: 32px;
}

.prayer-detail .user-prayer-table {
  padding: 48px;
}

.prayer-detail .prayer-attrs .content {
  margin: 32px 72px 0px 72px;
}

.prayer-detail .user-prayer-table div.tab-content {
  overflow: auto;
}

.prayer-detail .user-prayer-table div.card-tabs {
  margin-top: 32px;
}

@media screen and (max-width: 720px) {
  .prayer-detail .prayer-attrs,
  .prayer-detail .user-prayer-table {
    padding: 16px;
  }
  .prayer-detail .prayer-attrs .content {
    margin: 16px 24px 0px 24px;
  }
}

.groups div.table .table-row > .table-cell > div.group-name {
  cursor: pointer;
}

.groups div.table .table-row > .table-cell > div.group-name > span {
  padding-left: 22px;
  font-weight: bold;
  font-size: 18px;
}

.group .group-section {
  margin: 32px 0px;
}

.group .group-detail,
.group .group-edit,
.group .group-create {
  position: relative;
  padding: 24px 36px;
}

.group .group-detail .group-status {
  position: absolute;
  right: 0;
  top: 0;
}

.group .group-detail .group-status svg {
  font-size: 36px;
  height: 0.875em;
  width: 0.875em;
  box-sizing: content-box;
  margin: 4px;
}

.group .group-detail .members-content,
.group .group-detail .notifications-content,
.group .group-detail .events-content {
  margin: 24px;
}

.group .group-detail .group-section .group-details-title {
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
}

.group .group-detail .group-section .collapsible-paragraph-container {
  margin-bottom: 24px;
}

.group .group-detail .group-section .group-details-created {
  font-weight: bold;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #8b8b8b;
}

.group .group-create .react-autosuggest__suggestions-container--open {
  position: absolute;
  left: 15px;
  right: 15px;
  z-index: 1;
  background: white;
  padding: 5px 0px 15px 0px;
  border: 2px solid #fe9168;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.group .group-create .react-autosuggest__suggestions-container--open ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.group .group-create .react-autosuggest__suggestions-container--open ul li {
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
}

.group .group-create .react-autosuggest__suggestions-container--open ul li:hover,
.group .group-create .react-autosuggest__suggestion--highlighted {
  color: white;
  background-color: #fe9168;
}

@media screen and (max-width: 767px) {
  .group .group-detail,
  .group .group-edit,
  .group .group-create {
    padding: 12px 18px;
  }
}

@media screen and (max-width: 434px) {
  .group .group-detail,
  .group .group-edit,
  .group .group-create {
    padding: 0px;
  }
}

@font-face {
    font-family: 'icomoon';
    src: url(/static/media/icomoon.f1c2dae0.eot);
    src: url(/static/media/icomoon.f1c2dae0.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.4267c8ce.ttf) format('truetype'),
    url(/static/media/icomoon.d9b31276.woff) format('woff'),
    url(/static/media/icomoon.5a722b61.svg#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: Source Sans Pro, sans-serif, Libre Baskerville;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px transparent;
}

:root {
    --swiper-theme-color: #fe9168;
}

html {
    width: unset !important;
    position: relative !important;
    overflow: unset !important;
}

body {
    position: relative;
    background: linear-gradient(180deg, #feb37d 0, #feb37d 0, #ff8867 51%, #feb37d);
    overflow-x: hidden;
}

.app-loading-container {
    position: relative;
    background: linear-gradient(180deg, #fafafa 0, #fefefe 0, #ffffff 51%, #fafafa);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
}

.app-content {
    position: relative;
}

.app-header-spacer {
    height: 140px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    background: transparent;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

textarea:disabled {
    resize: none;
}

body {
    margin: 0;
    padding: 0;
}

svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: 28px;
    overflow: visible;
    font-size: inherit;
}

a,
a:hover {
    color: inherit;
}

a.link {
    cursor: pointer;
    color: #0275d8;
    text-decoration: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

button.link {
    cursor: pointer;
    color: #fe9168;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
    font-weight: bold;
}

.form-control:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.color-red {
    color: #fe9168;
}

.color-danger {
    color: #f44336;
}

.color-lightblue {
    color: #31c5c3;
}

.red-large-text {
    font-size: 22px;
    color: #fe9168;
    line-height: 38px;
}

.form-control::-webkit-input-placeholder {
    font-style: italic;
}

.form-control::placeholder {
    font-style: italic;
}

a:hover {
    text-decoration: inherit;
    cursor: pointer;
}

:focus {
    outline: 0;
}

input:focus {
    outline: 5px auto rgba(0, 123, 255, 0.25);
}

.MuiSelect-select.MuiSelect-select:focus {
    border-radius: 4px;
}

.customWidth {
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
}

.back {
    position: absolute;
    top: 8px;
    left: 54px;
    font-size: 18px;
    color: white;
    font-weight: bold;
}

.back > svg {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

.back a,
.back button {
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
    text-transform: uppercase;
    font-weight: inherit;
    font-size: 14px;
    letter-spacing: 2px;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    border-bottom-color: inherit;
    padding: 0;
    text-decoration: none;
}

.card-wrapper {
    position: relative;
    padding: 46px 52px;
}

.card {
    border-radius: 30px;
    border: 0;
    box-shadow: 1px 1px 8px 0 #858585;
    overflow: hidden;
}

.card.transparent-card {
    box-shadow: none;
    background: transparent;
}

.card.border-card {
    border: 2px solid #f2f2f2;
}

.card > .card-header {
    position: relative;
    display: flex;
    align-items: stretch;
    padding: 0;
    background: #ededed;
    border-bottom: 2px solid #f2f2f2;
    flex-wrap: wrap;
}

.card.transparent-card > .card-header {
    background: inherit;
}

.card > .card-header > .title-buttons {
    padding: 0px 24px;
    display: flex;
    align-items: center;
}

.card > .card-header > .title-buttons > .button {
    margin: 12px;
}

.card > .card-header > .title {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding: 0.907rem 1.25rem;
    vertical-align: bottom;
    min-width: 320px;
    min-height: 0px;
}

.card > .card-header > .title.center {
    justify-content: center;
}

.card > .card-header > .title > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.card > .card-header p {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 8px;
    letter-spacing: 1px;
    vertical-align: text-bottom;
}

.card > .card-block {
    position: relative;
    padding: 1.25rem;
    overflow: auto;
}

.section-heading {
    font-size: 22px;
    color: #fe9168;
    line-height: 38px;
    margin-bottom: 24px;
}

/* Tooltip */
svg.icon-info {
    border: 2px solid #fe9168;
    color: #fe9168;
    box-sizing: content-box;
    width: 12px !important;
    height: 12px !important;
    padding: 4px;
    border-radius: 999px;
    cursor: pointer;
}

/* Buttons */
.button {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    border-radius: 4em;
    cursor: pointer;
    color: #fff;
    width: auto;
    height: auto;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    display: inline-block;
    -webkit-user-select: none;
            user-select: none;
}

.button:disabled {
    cursor: not-allowed !important;
}

.button:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.button.right-top {
    position: absolute;
    right: 16px;
    top: 16px;
}

.button.icon-button {
    height: 42px;
    width: 42px;
    font-size: 20px;
    line-height: 42px;
    margin: 4px;
    border-radius: 8px;
}

.button.small {
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0px 16px;
    min-width: 90px;
    min-height: 38px;
}

.button.medium {
    font-size: 14px;
    padding: 0px 24px;
    letter-spacing: 2px;
    min-width: 178px;
    min-height: 40px;
}

.button.large {
    font-size: 20px;
    padding: 0px 48px;
    letter-spacing: 2px;
    min-width: 178px;
    min-height: 64px;
}

.button.black {
    background-color: #323232;
    color: #fff;
    border: none;
}

.button.lightblue {
    background-color: #31c5c3;
    color: #fff;
    border: none;
}

.button.red {
    background-color: #fe9168;
    color: #fff;
    border: none;
}

.button.gray {
    background-color: #777;
    color: #fff;
    border: none;
}

.button.gradient {
    background: linear-gradient(180deg, #feaf78 0, #fe8563 0, #fc8362 0, #feaf78 0, #f57754);
    border: none;
}

.button.gradient:disabled {
    color: #d9d9d9;
    background: linear-gradient(180deg, #dbdbdb 0, #e6e4e3 0, #d2cbca 0, #bfb8b4 0, #d7d7d7);
    cursor: not-allowed;
}

.button.neutral {
    background-color: transparent;
    border: 3px solid #fff;
}

.button.neutral:disabled {
    background-color: #00000020;
    border: 3px solid #00000020;
    color: #00000020;
}

.button.border--red {
    border-color: #fe9168;
    color: #fe9168;
}

.button.border--gray {
    border-color: #d7d7d7;
    color: #878787;
}

.action-group {
    text-align: center;
    margin-top: 42px;
}

.action-group button {
    text-transform: uppercase;
    border-radius: 4em;
    cursor: pointer;
    color: #fff;
    border: none;
    width: 178px;
    height: 38px;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 9px;
}

.action-group .btn-save {
    background: linear-gradient(180deg, #feaf78 0, #fe8563 0, #fc8362 0, #feaf78 0, #f57754);
}

.action-group .btn-cancel {
    background-color: transparent;
    border: 3px solid #fe9168;
    color: #fe9168;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.form-group span.helper-text {
    display: block;
    position: relative;
    bottom: -5px;
    font-size: 14px;
    line-height: 12px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    text-align: left;
}

span.helper-text.error {
    color: rgb(244, 67, 54);
}

.form-label {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    margin-top: 0.5rem;
    padding-right: 15px;
    padding-left: 15px;
    overflow: visible;
    white-space: nowrap;
}

.item-label {
    font-weight: bold;
    margin-bottom: 20px;
}

.item-value {
    margin-bottom: 20px;
}

.name {
    white-space: break-spaces;
    word-break: break-word;
    text-align: center;
}


@media screen and (max-width: 960px) {
    .card > .card-header.lg-column {
        flex-direction: column;
    }
}

@media screen and (max-width: 776px) {
    .card > .card-header {
        flex-direction: column;
        align-items: stretch;
    }

    .card > .card-header > .title-buttons {
        padding: 0 16.5px;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: center;
    }

    .button.large {
        padding: 0px 48px;
    }
}

@media screen and (max-width: 480px) {
    .card-wrapper {
        padding: 46px 10px !important;
    }

    .card > .card-header > .title-buttons .button {
        flex: 1 1;
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    .back {
        left: 14px;
    }

    .button.large {
        padding: 0px 28px;
        font-size: 14px;
        letter-spacing: 1px;
    }
}

/* Tabs */

.card-tabs {
    border-radius: 30px;
    border: 0;
    box-shadow: 1px 1px 8px 0 #858585;
    position: relative;
    overflow: hidden;
}

.card-tabs .nav-tabs .nav-link {
    flex: 1 1;
    border: none;
    border-radius: 0;
    line-height: 60px;
    padding: 0px;
}

.card-tabs .nav-tabs .nav-link > span {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
}

.card-tabs .nav-tabs .nav-item {
    height: 60px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    background-color: #ededed;
    color: #3f3f3f;
    border: none;
    border-radius: 0;
    border-right: 2px solid #e0e0e0;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.card-tabs .nav-tabs .nav-item.active {
    background: #c4c4c4;
    color: #fff;
}

.card-tabs .nav-tabs .nav-item:last-child {
    border: none;
}

.card-tabs .tab-content {
    position: relative;
    min-height: 344px;
    background-color: #fff;
}

@media screen and (max-width: 676px) {
    .card-tabs .nav-tabs .nav-item .nav-link {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 550px) {
    .card-tabs .nav-tabs {
        display: block;
    }


    .card-tabs .nav-tabs .nav-item {
        width: 100%;
    }

    .card-tabs .nav-tabs .nav-item {
        font-size: 1rem;
        border-right: 0;
        border-bottom: 3px solid #e0e0e0;
    }

    .card-tabs .nav-tabs .nav-item:last-child {
        border-top-right-radius: 0;
        border-bottom: none;
    }

    .card-tabs .nav-tabs .nav-item:first-child {
        border-top-right-radius: 30px;
    }
}

/* Table */
.custom-scrollbars .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 0px;
}

.custom-scrollbars .table-header > div {
    background-color: white;
}

div.table {
    position: relative;
    background: white;
    box-sizing: border-box;
    border: 2px solid #f2f2f2;
    overflow: hidden;
    width: auto;
}

div.table.rounded {
    border-radius: 30px !important;
}

div.table > .table-header {
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

div.table .table-row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 2px solid #f2f2f2;
}

div.table .table-row > .table-cell {
    min-height: 60px;
    padding: 8px;
    border-right: 2px solid #f2f2f2;
}

div.table .table-row > .table-cell:last-child {
    border-right: none;
}

div.table > .table-header > .table-row > .table-cell {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

