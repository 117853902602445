.church-info,
.church-contact-info,
.church-social-media,
.church-admin-panel {
  padding: 38px 28px;
  border-bottom: 2px solid #eceded;
}

.church-info-detail,
.church-contact-info-detail {
  padding: 14px 22px 0;
}

.church-info-detail .form-control,
.church-contact-info-detail .form-control,
.church-social-media .form-control,
.church-contact-info-address .contact-info-state,
.church-contact-info-address .contact-info-country {
  height: 50px;
}

.form-text {
  width: 100%;
  line-height: 32px;
  font-size: 16px;
  padding: 0.5rem 8px;
  height: 50px;
  margin: 0;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-text.text-empty {
  font-style: italic;
  color: #6c757d;
}

.w-fixed-label {
  width: 120px;
}

.church-profile .form-label {
  text-align: right;
}

.church-profile .form-control:disabled {
  background: transparent;
  border: none;
  cursor: default;
}

.church-profile .form-control:disabled::placeholder {
  color: transparent;
}

/* Church Information */

.church-info-detail .church-info-desc {
  display: flex;
  margin-top: 24px;
}

.church-info-detail .church-info-desc .form-control {
  height: 200px;
  padding: 18px 16px;
}

/* Contact Information */
.church-contact-info-phone,
.church-contact-info-address {
  display: flex;
}

.church-contact-info-detail .row {
  width: 100%;
}

.church-contact-info-detail .church-contact-info-address .contact-info-item {
  margin-top: 8px;
  margin-bottom: 8px;
}

.church-contact-info-detail .church-contact-info-address .btn-group .dropdown-toggle.disabled {
  color: #000;
}

/* Social Media */
.church-social-media .form-group {
  position: relative;
  width: 100%;
}

.church-social-media .form-label,
.church-social-media .form-label img {
  width: 50px;
  height: 50px;
  margin: 0 !important;
  padding: 0 !important;
}

.church-social-media .form-text,
.church-social-media .form-control {
  width: auto;
  flex: 1;
}

.action-group.profile-action p {
  color: #000;
  font-size: 18px;
  font-style: italic;
  margin-top: 20px;
}

.church-admin-panel .card .card-header .title {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 26px;
  letter-spacing: 1px;
}

.admin-panel-edit span.table-action {
  font-size: 18px;
  color: #31c5c3;
  display: block;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

/*  AdminPanelAdd  */
.admin-panel-detail {
  margin: 38px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-panel-detail .search-box {
  max-width: 480px;
}

.admin-panel-detail .apa-selected-user p {
  font-style: normal;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
}

.admin-panel-detail .apa-selected-user .apas_name span {
  border-bottom: 1px solid;
  font-weight: bold;
}

.admin-panel-detail .apa-selected-user .apas_email {
  margin-bottom: 42px;
}

.admin-panel-detail .apa-permission {
  font-size: 18px;
}

.admin-panel-detail .apa-permission p {
  text-transform: uppercase;
  font-weight: bold;
}

.admin-panel-detail .apa-permission .section-title {
  font-style: italic;
  text-decoration: underline;
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 1324px) {
  /* Contact Information */
  .church-info-detail .form-control::placeholder {
    font-size: 14px;
  }
}

@media screen and (max-width: 810px) {
  /* Social Media */
  .church-social-media .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .church-admin-panel .card-block .social-main-table {
    display: block;
    overflow-x: auto;
  }

  .church-admin-panel .card-block tbody tr td:first-child {
    min-width: 194px;
  }

  .church-admin-panel .card-block .table-email {
    min-width: 239px;
  }

  .church-admin-panel .card-block .table-group {
    min-width: 132px;
  }
}

@media screen and (max-width: 767px) {
  .form-label {
    text-align: left;
  }

  /* Church Information Responsive */
  .church-info-detail .church-info-desc {
    display: block;
  }

  /* Contact Information */
  .church-contact-info-phone,
  .church-contact-info-address {
    display: block;
  }

  /* Social Media */
  .church-social-media .form-group {
    padding: 0 22px;
  }

  .church-social-media .form-group:first-child {
    margin-bottom: 16px;
  }

  .church-social-media .form-group .form-control::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 434px) {
  /* Social Media */
  .church-social-media .form-group .form-control::placeholder {
    font-size: 14px;
  }

  .church-social-media .form-group {
    padding: 0;
  }

  .admin-panel-detail {
    margin: 0px;
  }
}

@media screen and (max-width: 396px) {
  /* Church Information Responsive */
  .church-info-detail,
  .church-contact-info-detail {
    padding: 14px 0px;
  }

  .church-info-detail .row,
  .church-info-detail .church-info-desc {
    display: block;
  }

  .church-info-detail .church-info-desc {
    margin-top: 0;
  }

  /* Social Media */
  .church-social-media .form-group .form-control::placeholder {
    font-size: 11px;
  }

  .action-group.profile-action p {
    font-size: 16px;
  }

  .church-admin-panel .card .card-header .title {
    font-size: 14px;
  }

  /*  AdminPanelAdd  */
  .admin-panel-detail .apa-permission {
    font-size: 16px;
  }
}
