.gift-settings {
  padding: 70px 28px;
}

.gs-payments {
  margin-bottom: 62px;
}

.gs-payments .card-header,
.gs-payments .card-block {
  text-align: center;
}

.gs-payments .card-header .title p {
  margin-left: 0;
  padding: 0 16px;
}

.gs-payments .card-block {
  padding: 30px 74px 46px;
}

.gs-payments .pgs-detail .red-large-text {
  margin-bottom: 38px;
}

.gs-payments .pgs-detail .red-large-text:first-child {
  margin-bottom: 0;
}

.gift-wrapper .gift-add {
  text-align: right;
  margin-bottom: 24px;
}

.gift-wrapper .card-header {
  display: flex;
}

.gift-wrapper .card-header .right-title {
  padding: 0.907rem 1.25rem;
}

.gift-wrapper .card-header .title,
.gift-wrapper .card-block .go-detail {
  position: relative;
  flex: auto;
  width: 80%;
}

.gift-wrapper .card-header .right-title,
.gift-wrapper .card-block .go-as {
  width: 20%;
  text-align: center;
}

.gift-wrapper .card-header .title::after {
  content: '';
  background: #f2f2f2;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0px;
}

.gift-wrapper .card-block .go-detail::after {
  content: '';
  background: #f2f2f2;
  width: 2px;
  min-height: 252px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

.gift-wrapper .card > .card-block {
  display: flex;
  padding: 0;
}

.gift-wrapper .card-header .title p {
  margin-left: 36px;
}

.gift-wrapper .go-detail ul,
.gift-wrapper .card-block .go-as ul {
  padding-left: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.gift-wrapper .go-detail ul li,
.gift-wrapper .card-block .go-as ul li {
  list-style: none;
  color: #4d4d4d;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 36px;
}

.gift-wrapper .card-block .go-as ul li {
  padding: 11px 0;
  justify-content: center;
}

.gift-wrapper .gift-option {
  margin-bottom: 40px;
}

.gift-wrapper .go_active_wrapper {
  display: flex;
}

.gift-wrapper .go-detail ul li button {
  width: 40px;
  height: 42px;
  line-height: 1;
  border: none;
  background: #fe9e6f;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.gift-wrapper .go-detail ul li span {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gift-wrapper .go-detail ul li button + span {
  padding-left: 8px;
}

/* Responsive */
@media screen and (max-width: 920px) {
  .gs-payments .card-block {
    padding: 30px 40px 46px;
  }

  .gift-wrapper .card-header .title,
  .gift-wrapper .card-block .go-detail {
    width: 70%;
  }

  .gift-wrapper .card-header .right-title,
  .gift-wrapper .card-block .go-as {
    width: 30%;
  }
}

@media screen and (max-width: 776px) {
  .gift-wrapper .card > .card-header {
    flex-direction: row;
  }

  .gift-settings {
    padding: 0px;
  }
}

@media screen and (max-width: 641px) {
  .gift-wrapper .card-header .title {
    text-align: center;
  }

  .gift-wrapper .card-header .title p {
    margin-left: 12px;
  }

  .gift-wrapper .card-header .title,
  .gift-wrapper .card-block .go-detail {
    width: 60%;
  }

  .gift-wrapper .card-header .right-title,
  .gift-wrapper .card-block .go-as {
    width: 40%;
  }

  .gift-wrapper .card-header .right-title p,
  .gift-wrapper .card-block .go-as p,
  .gift-wrapper .inactive-gift-option .card-header .title p {
    line-height: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 526px) {
  .gift-settings .gift-add > .button.gray {
    display: block;
    width: 100%;
  }

  .gift-wrapper .card-header p,
  .gift-wrapper .card-block .go-as p {
    font-size: 14px;
  }

  .gift-wrapper .go-detail ul li {
    padding: 0px 8px;
  }
}

@media screen and (max-width: 450px) {
  .gs-payments .card-block {
    padding: 30px 20px 46px;
  }
}

@media screen and (max-width: 370px) {
  .gs-payments .card-header .title p {
    line-height: 22px;
    padding-top: 10px;
  }

  .gift-wrapper .inactive-gift-option .card-block .go-detail {
    padding-left: 10px;
  }

  .gift-wrapper .inactive-gift-option .card-header .title p {
    margin-left: 18px;
  }
}
